
const getValue = (rawProduct, valueToFetch, defaultVal = null) => {
    if(!rawProduct[valueToFetch]) return defaultVal;
    else return rawProduct[valueToFetch][0];
}

const getList = (rawProduct, valueToFetch, defaultVal = null) => {
    if(!rawProduct[valueToFetch]) return defaultVal;
    else return rawProduct[valueToFetch];
}

const SolrField = {
    getValue,
    getList
}

export default SolrField;