import {useState} from "react";

const isObject = (value) => {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
}

const areEqual = (val1, val2) => {
    if((Array.isArray(val1) && Array.isArray(val2))
        || (isObject(val1) && isObject(val2))) {
        return JSON.stringify(val1) === JSON.stringify(val2);
    } else {
        return val1 === val2;
    }
}

const dependantsTheSame = (storedDependants, dependants) => {
    if(!storedDependants !== !dependants) return false;

    if(!storedDependants && !dependants) return true;

    if(storedDependants.length !== dependants.length) return false;

    for(const i in storedDependants) {
        const firstDependant    = storedDependants[i];
        const secondDependant   = dependants[i];
        if(!areEqual(firstDependant, secondDependant))
            return false;
    }

    return true;
}

const useValueChangeListener = (runOnChange, dependants, defaultDependants) => {

    const initialDependants = defaultDependants ? defaultDependants : dependants;
    const [ storedDependants, setStoredDependants ] = useState(initialDependants);

    if(!dependantsTheSame(storedDependants, dependants)) {
        setStoredDependants(dependants);
        runOnChange();
    }

}

export default useValueChangeListener;
