import FilterContainer from "../general/filter/FilterContainer";
import FilterGroup from "../general/filter/FilterGroup";
import FilterItemSelect from "../general/filter/FilterItemSelect";
import FilterUpdate from "../general/filter/FilterUpdate";
import {useState} from "react";
import filterSelection from "../../logic/filter/filterSelection";
import FilterUrl from "../../logic/filter/filterUrl";
import MapUtil from "../../logic/collection/mapUtil";
import useValueChangeListener from "../../services/state/general/useValueChangeListener";
import FilterSelectionAction from "../../logic/filter/filterSelectionAction";

const getInitialFilterSelection = (defaultParam) => {
    if(defaultParam) {
        return MapUtil.reduce(
            FilterUrl.getMap(defaultParam),
            (k,v,s) => v.reduce((a,name) => a.selectFilter(k,{name}), s),
            filterSelection()
        );
    } else {
        return filterSelection();
    }
}

const compareAttributeNames = (thisFilter, thatFilter) => {
    const thisName = thisFilter.attribute.name;
    const thatName = thatFilter.attribute.name;

    if(thisName > thatName) {
        return -1;
    } else if(thatName > thisName) {
        return 1;
    } else {
        return 0;
    }
}

const compareAttributeSeq = (thisFilter, thatFilter) => {
    const thisSeqNo = thisFilter.attribute.seqNo;
    const thatSeqNo = thatFilter.attribute.seqNo;

    if(thisSeqNo != null || thatSeqNo != null) {
        if (thisSeqNo == null) {
            return 1;
        } else if (thatSeqNo == null) {
            return -1;
        } else {
            if(thisSeqNo > thatSeqNo) return 1;
            else if(thisSeqNo < thatSeqNo) return -1;
            else return 0;
        }

    } else {
        return 0;
    }
}

const compareAttributeUseSeq = (thisFilter, thatFilter) => {
    const thisHasUses = thisFilter.attribute.use?.length > 0;
    const thatHasUses = thatFilter.attribute.use?.length > 0;

    if(thisHasUses || thatHasUses) {
        if (!thisHasUses) {
            return -1;
        } else if (!thatHasUses) {
            return 1;
        } else {

            const [ thisAttr, thisSeq ] = thisFilter.attribute.use[0];
            const [ thatAttr, thatSeq ] = thatFilter.attribute.use[0];

            if(thisAttr === thatAttr) {
                if(thisSeq > thatSeq) return 1;
                else return -1;
            } else {
                if(thisAttr > thatAttr) return 1;
                else return -1;
            }
        }
    } else {
        return 0;
    }

}

const compareFilters = (thisFilter, thatFilter) => {

    const compareSeq = compareAttributeSeq(thisFilter, thatFilter);
    if(compareSeq !== 0) return compareSeq;

    const compareUseSeq = compareAttributeUseSeq(thisFilter, thatFilter);
    if(compareUseSeq !== 0) return compareUseSeq;

    return compareAttributeNames(thisFilter, thatFilter);
}

const ProductFilter = ({ filters, onApply, defaultParam, dependants }) => {

    const [ selectedFilters, setSelectedFilters ] = useState(getInitialFilterSelection(defaultParam));
    useValueChangeListener(() => setSelectedFilters(filterSelection()), dependants);

    const onSelectChange = (item) => (selected) => {
        const filter = filters[item.attributeID];
        const updated = FilterSelectionAction.onSelectionUpdate(selectedFilters, filter, item, selected);
        setSelectedFilters(updated);
    }

    const applyFilters = () => {
        if(onApply) onApply(selectedFilters.getReducedMap());
    }


    const clearFilters = () => {
        const blank = filterSelection();
        setSelectedFilters(blank);
        if(onApply) onApply(blank.getReducedMap());
    }

    const filterList = Object.values(filters).sort(compareFilters);

    return (
        <FilterContainer header="Product Filter">
            {filterList.map(group =>
                <FilterGroup
                    name={group.attribute.name}
                    key={group.attribute.id}
                    defaultExpanded={group.options.length < 5}
                >
                    {group.options.map((item,i) =>
                        <FilterItemSelect
                            name={item.name}
                            key={i}
                            selected={selectedFilters.isSelected(item.attributeID, item.name)}
                            onSelectChange={onSelectChange(item)}
                        />
                    )}
                </FilterGroup>
            )}
            <FilterUpdate
                onApply={applyFilters}
                onClear={clearFilters}
            />
        </FilterContainer>
    )
}

export default ProductFilter;
