import '../../style/general/input/checkbox.css'
import {useState} from "react";
import {ReactComponent as TickIcon} from '../../../../res/icon/tick.svg';

const Checkbox = ({ isChecked: defaultChecked, onCheckedChange }) => {

    const [ isChecked, setChecked] = useState(defaultChecked);

    const handleClick = () => {
        setChecked(!isChecked);
        onCheckedChange(!isChecked);
    }

    return (
        <div
            className={`checkbox-container${isChecked ? " checked" : ""}`}
            onClick={handleClick}
        >
            {isChecked && <TickIcon/>}
        </div>
    )

}

export default Checkbox;