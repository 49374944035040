import useSession from "../../services/state/session/useSession";
import SignInBanner from "./SignInBanner";
import {useNavigate} from "react-router-dom";

const SIGN_IN_PROMPT = "Sign in to see our latest promotions";

const onClick = (signedIn, navigate) => {
    if(!signedIn) {
        navigate("/signIn");
    } else {
        navigate("/promotion");
    }
}

const SignInBannerWithState = () => {

    const navigate = useNavigate();

    const session = useSession();
    const signedIn = session.isSignedIn;

    const promoPrompt = `Welcome back ${session.getUserName()} - see the promotions available to you today`
    const text = signedIn ? promoPrompt: SIGN_IN_PROMPT;
    const buttonText = signedIn ? "See your offers" : "Sign in";

    return (
        <SignInBanner
            text={text}
            onClick={() => onClick(signedIn, navigate)}
            buttonText={buttonText}
        />
    )


}

export default SignInBannerWithState;