import searchRequest from "../../rest/request/search/searchRequest";
import useMultiRequestTracker from "../request/useMultiRequestTracker";
import ProductPurchaseInfoRequest from "../../rest/request/product/productPurchaseInfoRequest";
import {useSelector} from "react-redux";
import {useState} from "react";
import SolrProduct from "../../../logic/search/solrProduct";
import ListUtil from "../../../logic/collection/listUtil";
import SolrField from "../../../logic/search/solrField";

const isProduct = (solrResult) => {
    return SolrField.getValue(solrResult, "Table_Name") === "M_Product";
}

const formatInitialResult = (isLoggedIn) => (solrResult) => {
    if(isProduct(solrResult)) {
        const formattedProduct = SolrProduct.formatProduct(solrResult);
        return {
            ...formattedProduct,
            isBrochure: !isLoggedIn,
            isProduct: true
        }
    } else {
        return solrResult;
    }
}

const formatFinalResult = (result, purchaseInfo, requestTracking) => {
    if(!result.isProduct) return result;

    const loadingInfo = requestTracking?.loading;
    if(!purchaseInfo && loadingInfo) {
        return {
            ...result,
            loadingPurchaseInfo: true
        };
    }

    if(!purchaseInfo) {
        return result;
    }

    const info = purchaseInfo[result.id];
    if(info)
        return {...result, ...info}
    else
        return {...result, loadingPurchaseInfo: loadingInfo };

}

const useSearch = () => {

    const {
        trackedRequests,
        sendIt
    } = useMultiRequestTracker();

    const { session } = useSelector(
        state => state.local.auth
    )

    const isLoggedIn = session?.bPartnerID > 0;

    const [ result, setResult ] = useState();
    const [ purchaseInfo, setPurchaseInfo ] = useState();

    const onPurchaseInfoReceived = (result) => {
        const purchaseInfoMap = {};
        result.forEach(p => purchaseInfoMap[p.id] = p);
        setPurchaseInfo(purchaseInfoMap);
    }

    const handleProductPrices = (searchResults) => {
        const pids = ListUtil.filterTransform(searchResults, s => s.isProduct, s => s.id);
        if(isLoggedIn && pids.length > 0) {
            const req = ProductPurchaseInfoRequest(session.orgID, session.bPartnerID)
                .productIDs(pids);
            // TODO use auth request
            sendIt("purchaseInfo", () => req.sendRequest(session), onPurchaseInfoReceived);
        }
    }

    const onResultsReceived = (results) => {
        const formattedResults = results.searchResults.map(formatInitialResult(isLoggedIn));
        handleProductPrices(formattedResults);
        setResult({
            ...results,
            searchResults: formattedResults
        });
    }

    const search = (term, pageNo, pageSize) => {
        if(!term || term.length < 3) return;
        const req = searchRequest()
            .setPageNo(pageNo)
            .setPageSize(pageSize)
            .setSearchTerm(term)
            .setPriceList(session?.priceListID)

        sendIt("searchTerm", req.sendRequest, onResultsReceived);
    }

    const loading = trackedRequests?.searchTerm?.loading;
    const error   = trackedRequests?.searchTerm?.error;

    const priceLoading = trackedRequests?.purchaseInfo?.loading;
    const priceError   = trackedRequests?.purchaseInfo?.error;

    const results = result?.searchResults.map(res => formatFinalResult(res, purchaseInfo, trackedRequests?.purchaseInfo))

    return {
        loading,
        error,
        search,
        priceLoading,
        priceError,
        purchaseInfo,
        searchTerm: result?.searchTerm,
        results,
        count: result?.count
    }

}

export default useSearch;
