import {env} from "../../../../../res/config/env";
import axios from "axios";

const getValue = (rawProduct, valueToFetch, defaultVal = null) => {
    if(!rawProduct[valueToFetch]) return defaultVal;
    else return rawProduct[valueToFetch][0];
}

const getProductCondition = (productIDs) => {
    return productIDs
        .reduce((str, id) => {
            const cond = "(matchingProducts:" + id + ")";
            return str ? (str + "OR" + cond) : cond;
        }, null);
}

const buildValueRequestUrl = ({ categoryID, productIDs }) => {

    const url = env.API_SOLR_URL;
    const select = "select?q="

    const paramList = "(Table_Name:filter_value)";
    const categoryCond = categoryID ? "AND(categoryChain:"+categoryID+")" : "";
    const productCond = productIDs ? "AND(" + getProductCondition(productIDs) + ")" : ""

    const pageSize = "&rows=" + 1000

    return url + select + paramList + categoryCond + productCond + pageSize;
}

const buildAttributeRequestUrl = () => {

    const url = env.API_SOLR_URL;
    const select = "select?q="

    const paramList = "(Table_Name:filter_attribute)";

    const pageSize = "&rows=" + 200

    return url + select + paramList + pageSize;
}

const formatAttribute = (rawAttribute) => {

    const attribute = {};

    attribute.type  = getValue(rawAttribute, "attributeType");
    attribute.setID = getValue(rawAttribute, "m_attributeSet_ID");
    attribute.id    = getValue(rawAttribute, "m_attribute_ID");
    attribute.name  = getValue(rawAttribute, "name");
    attribute.use   = rawAttribute.use?.map(l => [l.split("-")]);

    return attribute;
}

const formatValue = (rawValue) => {

    const value = {};

    value.setID             = getValue(rawValue, "m_attributeSet_ID");
    value.attributeID       = getValue(rawValue, "m_attribute_ID");
    value.seqNo             = getValue(rawValue, "seqNo");
    value.name              = getValue(rawValue, "value");
    value.categoryChain     = rawValue.categoryChain;
    value.referenceIDs      = rawValue.matchingProducts;

    return value;
}

const solrFiltersRequest = () => {

    const requestBuilder = {};
    const params = {};

    requestBuilder.setCategoryID = (categoryID) => {
        params.categoryID = categoryID;
        return requestBuilder;
    }

    requestBuilder.setProductIDs = (...productIDs) => {
        params.productIDs = productIDs;
        return requestBuilder;
    }

    requestBuilder.sendRequest = async () => {
        const valueUrl = buildValueRequestUrl(params);
        const valueRes = await axios.get(valueUrl);
        const valueResponse = valueRes.data.response;

        const attrUrl = buildAttributeRequestUrl(params);
        const attrRes = await axios.get(attrUrl);
        const attrResponse = attrRes.data.response;

        return {
            attributes: attrResponse.docs.map(formatAttribute),
            values: valueResponse.docs.map(formatValue)
        }
    }

    return requestBuilder;
}

export default solrFiltersRequest;