import {useEffect, useState} from "react";
import {request} from "../../res/rest/restRequest";
import {env} from "../../res/config/env";
import axios from "axios";
import {api} from "../../res/rest/api";
import {useSelector} from "react-redux";

const SNIPPET_ID = 'QVFJREFIaGo1T2pOSWpLWW9NR2I3V2FjTU9DR3JoaHRWaTZZQ1d1K0FuWmJjbnVkNHdFV0owVnpoMnpJZXJoZjRQYmc0YVVCQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNTC9yNHBHRjBvY0pka1c3M0FnRVFnQ3VKU283UXVqbDFIZHlBcTNxbTd5WlpIcE1heDhKV3EyL0poTElrb29xRCthNjl0UzVBc2ZVWTZjYXo6OmxoY0VSRUdUcVdIYkFpd3dDd1BYSjJjQWRUK0hDSGdUL1JtWUNSNGhxWkxkT0lFQVlkb1F5V0lYa1haNDhlZzRYV3F3d1JqMkIzeHNqK2ozcnNmZVhKTHdUUG0yVVJ4cFRSMVhsL0c0UTZIVi9SL004bVo0S1pxQkhIUmpRYU0xdWxKTTBaK0JiTVJZdm1NeW1OaE9oYnJ1RnE0TUxWUT0='
const SOURCE = 'https://d2zasqxhmd6ne4.cloudfront.net/amazon-connect-chat-interface-client.js'
const ID = '23160816-2d82-4af2-94cc-8c82e43ec54e'

const getTheToken = async (session) => {
    const req = request.process("getawsconnecttoken");

    const url = req.buildRequest(env.API_URL);
    const response = await axios.post(url, req.buildRequestBody(), api.auth(session))
    const token = response.data.summary;
    return token;
}

const isAttached = () => {
    const awsWidget = document.getElementById("amazon-connect-chat-widget");
    return Boolean(awsWidget);
}

const AmazonConnectChat = () => {

    const { session } = useSelector(state => state.local.auth);
    const [ attempts, setAttempts ] = useState(0);

    setTimeout(
        () => {
            if(!isAttached() && attempts < 5) {
                setAttempts(attempts + 1);
            }
        },
        1000
    );

    useEffect(() => {
        // Load the Amazon Connect Chat Interface Client script

        if(isAttached()) return;

        const script = document.createElement('script');
        script.src = SOURCE;
        script.async = true;
        script.id = ID;

        script.onload = () => {
            // Initialize the amazon_connect function
            window.amazon_connect = window.amazon_connect || function () {
                (window.amazon_connect.ac = window.amazon_connect.ac || []).push(arguments);
            };

            // Authenticate with Amazon Connect
            window.amazon_connect('authenticate', (callback) => {
                getTheToken(session).then(data => {
                    callback(data)
                })
            });

            window.amazon_connect('styles', { iconType: 'CHAT', openChat: { color: 'white', backgroundColor: '#00A2E0' }, closeChat: { color: 'white', backgroundColor: '#00A2E0'} });
            window.amazon_connect('snippetId', SNIPPET_ID);
            window.amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response' ]);

        };

        document.head.appendChild(script);

        // Cleanup script when the component is unmounted
        return () => {
            document.head.removeChild(script);
        };
    }, [ session, attempts ]);

    return null;
};

export default AmazonConnectChat;