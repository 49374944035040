import useAuthRequest from "../request/useAuthRequest";
import bankingRequest from "../../rest/request/account/bankingRequest";

const useBanking = () => {

    const {
        session,
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    if(!loading && !error && !result && session.isSignedIn) {
        const req = bankingRequest();
        sendIt(req.sendIt);
    }

    return {
        loading,
        error,
        banking: result
    }

}

export default useBanking;