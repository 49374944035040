import {Button} from "../../general/input/Button";
import TextArea from "../../general/input/TextArea";
import {useState} from "react";
import useCreateRequest from "../../../newStructure/services/state/helpDesk/useCreateRequest";

const HelpDeskRequest = ({item}) => {

    const [ userInput, setUserInput ] = useState();
    const [ warning, setWarning ] = useState();

    const {
        loading,
        createRequest,
        sent
    } = useCreateRequest();

    const onBlur = (e) => {
        const input = e.target.value;
        setUserInput(input)
        setWarning(null);
    }

    const buttonClass = loading ? "loading" : sent ? "inactive" : "";

    const onSubmit = () => {
        if(!userInput || userInput.length === 0) {
            setWarning("Input box cannot be empty")
            return;
        }

        if(!sent) {
            createRequest({
                requestTypeID: item.R_RequestType_ID.id,
                summary: userInput
            })
        }
    }

    return (
        <div className="tile help-desk-item">
            <div className="tile-header account-tile-header-container">
                <p className="account-tile-header-p">{item.Name}</p>
            </div>
            <div className="help-desk-tile-body">
                {!sent &&
                    <>
                        {item.Description &&
                            <p>{item.Description}</p>}
                        {warning &&
                            <p className="form-warning">{warning}</p>
                        }
                        <TextArea onBlur={onBlur} />
                    </>
                }
                {sent &&
                    <p>Thank you for submitting your request. We will get back to you as soon as possible!</p>
                }
                <Button className={buttonClass} onClick={onSubmit}>Submit</Button>
            </div>
        </div>
    )
}

export default HelpDeskRequest;