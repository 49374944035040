import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const NavDrawerAccount = () => {

    const navigate = useNavigate();

    const { session } = useSelector(
        state => state.local.auth
    );

    const navigateToAccount = () => {
        navigate("/account/")
    }

    const loggedIn = session?.bPartnerID;

    const title = loggedIn ? session.userName : "Login";

    const onAccountClick = () => {
        if(loggedIn) {
            navigateToAccount();
        } else {
            navigate("/");
        }
    }
    return (
        <>
            <div className="nav-drawer-account" onClick={onAccountClick}>
                <img alt="" src="/assets/Account_Light.svg" />
                <p>{title}</p>
            </div>
        </>
    )
}

export default NavDrawerAccount;