import StageBar, {STAGE_CART} from "./StageBar";
import Cart from "../cart/Cart";
import CartSummarySkeleton from "../cart/CartSummarySkeleton";
import CartSummary from "../cart/CartSummary";
import {useNavigate} from "react-router-dom";
import {nav} from "../../res/dataServices/navigation";
import {useSelector} from "react-redux";
import LayoutEmptyCart from "./LayoutEmptyCart";
import ReactGA from "react-ga4";

const trackBeginCheckout = (cart) =>  {
    try {
        const items = Object.values(cart.lines).map(item => ({
            item_id: item.M_Product_ID.id,
            item_name: item.M_Product_ID.name,
            price: item.LineNetAmt,
            quantity: item.QtyEntered,
            item_category: item.M_Product_ID?.M_Product_Category_Parent_ID?.identifier
        }));

        ReactGA.event('begin_checkout', {
            transaction_id: cart.order.DocumentNo,
            value: cart.order.TotalLines,
            currency: 'GBP',
            items: items
        });
    } catch (e) {
        console.log(e);
    }
}

const LayoutCart = ({ cart }) => {

    const navigate = useNavigate();

    const { categoryMap, loading: categoryLoading } = useSelector(
        state =>  state.session.category
    )

    const displayCartLoading = (!cart.lines || !categoryMap) && (cart.loading.cart || cart.loading.order
        || categoryLoading || !categoryMap);
    const displaySummaryLoading = cart.loading.cart || cart.loading.order || !cart.order;

    const cartIsEmpty = !displayCartLoading && (!cart.lines || Object.keys(cart.lines).length === 0);

    const onCheckoutClick = () => {
        trackBeginCheckout(cart);
        navigate(nav.ROUTE_CHECKOUT + nav.PARAM_PROMOTION);
    }

    return (
        <div>
            {cartIsEmpty ?
                <LayoutEmptyCart />
                :
                <div className="checkout-body">
                    <div style={{width: "100%", gridArea:"checkout-stage"}}>
                        <StageBar currentStage={STAGE_CART}></StageBar>
                    </div>
                    <div style={{width: "100%", gridArea:"checkout-content"}}>
                        <Cart categoryMap={categoryMap} lines={cart.lines} loading={displayCartLoading} />
                    </div>
                    <div style={{width: "100%", gridArea:"checkout-summary"}}>
                        {displaySummaryLoading ?
                            <CartSummarySkeleton/> :
                            <CartSummary
                                onCheckOutClick={onCheckoutClick}
                                guidePrice={cart.getProductTotal()}
                                total={cart.order.GrandTotal}
                                charges={cart.charges}
                            />
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default LayoutCart;
