import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Button} from "../../../general/input/Button";
import {api} from "../../../../res/rest/api";
import {condition, request} from "../../../../res/rest/restRequest";
import {env} from "../../../../res/config/env";
import SkeletonDiv from "../../../general/loading/SkeletonDiv";
import Selector2 from "../../../general/input/Selector2";
import axios from "axios";
import usePrint from "../../../general/output/usePrint";

const AccountLayoutStatements = () => {

    const { session } = useSelector(
        state => state.local.auth
    )
    const { printPreview } = usePrint();
    const [ periodData, setPeriodData ] = useState({});
    const [ printData, setPrintData ] = useState({});

    const getPeriodData = () => {
        if(periodData.periods) return;

        setPeriodData({ loading: true });
        const now = new Date().toISOString().slice(0, 10);
        const req = request.model("c_period")
            .select("name")
            .filter(condition.lessThan("startdate", now))
            .hostURL(env.API_URL)
            .orderBy("created DESC");

        api.getAllRequestPages(req, session)
            .then(res => {
                setPeriodData({periods: [null, ...res]})
            })
            .catch(e => {
                setPeriodData({ error: e })
            });
    }

    useEffect(() => {
        getPeriodData();
    },  // eslint-disable-next-line
        []);

    const printSelection = () => {
        if(printData.selection > 0 && !printData.loading) {
            setPrintData({ ...printData, loading: true, error: null});
            const selection = printData.selection;

            const req = request.process("statementreportjasper")
                .param("C_BPartner_ID", session.bPartnerID)
                .param("C_Period_ID", selection)
                .param("C_AcctSchema_ID", 1000000)
                .param("PostingType", "A")
                .param("isSOTrx", "Y")

            const url = env.API_URL + req.buildRequest();

            axios.post(url, req.buildRequestBody(), api.auth(session))
                .then(res => {
                    setPrintData({...selection, loading: false, error: null})
                    const data = res.data.exportFile;
                    printPreview(data);
                })
                .catch(e => {
                    setPrintData({...selection, loading: false, error: e})
                });

        }
    }

    return (
        <>
            <h1>Statements</h1>
            {periodData.loading ?
                <SkeletonDiv style={{width: "300pt", height: "40pt"}} /> :
                (periodData.error || ! Boolean(periodData.periods)) ?
                    <p>Oh no! Something has gone wrong! Try refreshing the page, if the problem persists please get in
                        touch with our team</p> :
                    <div style={{display: "flex", gap: "12pt"}}>
                        <Selector2
                            style={{maxWidth: "140pt"}}
                            options={periodData.periods}
                            optionToString={o => o?.Name}
                            onSelect={o => setPrintData({selection: o?.id})}
                        />
                        <Button
                            style={{ width: "200pt" }}
                            onClick={printSelection}
                            className={printData.loading ? "button-loading" : ""}
                        >
                            Print
                        </Button>
                    </div>
            }
        </>
    )
}

export default AccountLayoutStatements;