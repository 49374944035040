import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {persistor, store} from "./redux/store"
import {PersistGate} from "redux-persist/integration/react";
import {env} from "./res/config/env";
import ReactGA from "react-ga4";
import AmazonConnectChat from "./components/aws/AmazonConnectChat";

import(`./branding/${env.BRANDING}`)

const TRACKING_ID = "G-SM9ECCDKKJ";
ReactGA.initialize(TRACKING_ID,
    {
        //debug: true,
        gaOptions: {siteSpeedSampleRate: 100}
    });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <AmazonConnectChat />
              <App />
          </PersistGate>
      </Provider>
  </React.StrictMode>
);



const descriptionMeta = document.createElement('meta');
descriptionMeta.name = 'description';
descriptionMeta.content = 'New Description';
document.head.appendChild(descriptionMeta);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
