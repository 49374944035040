import {request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import axios from "axios";
import {idempiereProcess} from "../../../../../res/rest/process";

const requestPasswordResetRequest = () => {

    const req = request.process(api.PROCESS.REQUEST_PASSWORD_RESET);

    const builder = {};

    builder.email = (email) => {
        req.param("email", email);
        return builder;
    }

    builder.sendIt = async () => {
        const url = env.API_URL + req.buildRequest();
        const resp = await axios.post(url, req.buildRequestBody(), api.auth());
        const data = idempiereProcess.handleResponseThrowingException(resp);
        return data.summary;
    }

    return builder;

}

export default requestPasswordResetRequest;