import PageVendor from "./PageVendor";
import {useParams} from "react-router-dom";
import useVendorProducts from "../../../services/state/vendor/useVendorProducts";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import {Breadcrumb, BreadcrumbNavigation} from "../../navigation/Breadcrumb";
import useStoredBreadcrumb from "../../../services/state/navigation/useStoredBreadcrumb";
import ProductEditor from "../../vendor/product/ProductEditor";

const PageVendorProduct = () => {

    const { productID } = useParams();
    const {
        products,
        getProducts
    } = useVendorProducts();
    const {
        getBreadcrumb
    } = useStoredBreadcrumb();

    useValueChangeListener(() => getProducts({productIDs: [[productID]]}), [productID], []);

    const product = products ? products[0] : null;

    const thisCrumb = {name: product ? product.name : "loading", selected: true, nav: "/vendor/product/" + product?.id}
    const storedBreadcrumb = getBreadcrumb();
    const breadCrumb = storedBreadcrumb ? [...storedBreadcrumb, thisCrumb] : [{name: "Home", nav: "/"}, thisCrumb];

    return (
        <PageVendor>
            <div className="page-content">
                <BreadcrumbNavigation>
                    {breadCrumb.map(crumb =>
                        <Breadcrumb name={crumb.name} selected={crumb.selected} nav={crumb.nav} />
                    )}
                </BreadcrumbNavigation>
                {product &&
                    <ProductEditor product={product} />
                }
            </div>
        </PageVendor>
    )

}

export default PageVendorProduct;
