import useAuthRequest from "../request/useAuthRequest";
import createRequestRequest from "../../rest/request/helpDesk/createRequestRequest";

const useCreateRequest = () => {

    const {
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    const createRequest = ({ requestTypeID, summary }) => {
        const req = createRequestRequest()
            .setRequestTypeID(requestTypeID)
            .setSummary(summary);

        sendIt(req.sendIt);
    }

    return {
        loading,
        error,
        createRequest,
        sent: Boolean(result)
    }
}

export default useCreateRequest;