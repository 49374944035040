import {env} from "../../../res/config/env";
import {useNavigate} from "react-router-dom";

const logoStyle = {
    width: 206,
    height: "auto"
}


const Logo = () => {

    const navigate = useNavigate();

    const goToHome = () => {
        navigate("/");
    }

    return (
        <div className="logo-container" style={logoStyle}>
            <img src={env.CLIENT_LOGO + "logoWithText.svg"} alt="logo" onClick={goToHome}/>
        </div>
    )
}

export default Logo;