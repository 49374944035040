import {createAsyncThunk} from "@reduxjs/toolkit";
import {env} from "../../res/config/env";
import axios from "axios";
import {api} from "../../res/rest/api";
import {condition, request} from "../../res/rest/restRequest";
import {result} from "../../res/dataServices/result";
import {clearPayment} from "../slice/paymentSlice";
import ReactGA from "react-ga4";
import solrProductRequest from "../../newStructure/services/rest/request/product/solrProductRequest";
import ProductPurchaseInfoRequest from "../../newStructure/services/rest/request/product/productPurchaseInfoRequest";
import runAuthRequest from "../util/runAuthRequest";

// TODO Reload cart on things like checkout to make sure basket matches with the erp
// TODO TEST delete line on erp, then try to update
// TODO auth config not being handed to "Create Order", why is this allowed
// TODO remove hc priceListID and warehouseID
// TODO need to test what happens when create line is run twice -> on second run additional line of 1 qty will be
//     created, should probably just switch to using query to first check what is available in the basket
//     so create line will go (check for line matching, if exists -> update, else create)

const API_ORDER = "/api/v1/models/c_order";
const API_ORDER_LINE = "/api/v1/models/c_orderline"
export const API_PRODUCT = "/api/v1/models/m_product";

const getExistingCartID = async (session) => {

    const req = request.model(api.MODEL.C_ORDER)
        .select("DocumentNo","Created")
        .filter(condition.eq("C_BPartner_ID", session.bPartnerID))
        .filter(condition.eq("C_DocType_ID", "1000047"))
        .filter(condition.in("DocStatus","'DR'", "'IP'"))
        .filter(condition.eq("AD_Org_ID", session.orgID))
        .orderBy("Created desc")
        .top(1)
        .buildRequest(env.API_URL);

    const existingCartResp = await axios.get(req, api.auth(session));

    if(existingCartResp.data["row-count"] > 0) {
        return existingCartResp.data.records[0].id;
    }
    return 0;
}

const createCart = async (session) => {
    const cartData = {
        "AD_Org_ID": {
            "id": session.orgID,
            "tableName": "AD_Org"
        },
        "isActive": true,
        "description": "Web Order",
        "C_BPartner_ID": session.bPartnerID,

        "tableName": "C_Order",

        "PaymentRule": "T",
        "C_DocTypeTarget_ID": 1000047,
        "C_DocType_ID": {
            "identifier": "Web Order",
            "model-name": "c_doctype"
        },

        "IsSOTrx": true
    }

    const cartResp = await axios.post(env.API_URL + API_ORDER, cartData, api.auth(session));
    return cartResp.data;
}

const attachProducts = async (session, items) => {
    if(!items || Object.keys(items).length <= 0) return;
    const pidToLine = Object.values(items).reduce((map, line) => {
        const key = line.M_Product_ID?.id;
        if(!key) return map;
        if(!(key in map)) map[key] = [];
        map[key].push(line);
        return map;
    }, {})

    const solrList = Object.keys(pidToLine).map(pid => ({field: "M_Product_ID", value: pid}));
    if (solrList.length === 0) return;

    const { products } = await solrProductRequest()
        .pageSize(Object.keys(items).length + 10)
        .queryMatchingOrList(solrList)
        .sendRequest();


    const purchaseRequest = await ProductPurchaseInfoRequest(session.orgID, session.bPartnerID)
        .productIDs(products.map(p => p.id))
        .sendRequest(session);
    const purchaseInfoMap = {};
    purchaseRequest.forEach(p => purchaseInfoMap[p.id] = p);

    const linesWithProducts = {};
    for(const product of products) {

        const purchaseInfo = purchaseInfoMap[product.id];
        const fullProduct = {...product, ...purchaseInfo};

        pidToLine[product.id].forEach(orderLine => {
            const updateLine = {
                ...orderLine,
                M_Product_ID: fullProduct
            }
            linesWithProducts[updateLine.id] = updateLine;
        })
    }

    return linesWithProducts;
}

const getCartContents = async (session, dispatch, orderID) => {
    const items = await getCartLines(session, orderID);
    const charges = await getCharges(session, orderID);
    // const charges = MapUtil.filterValues(items, i => i.C_Charge_ID?.id > 0)
    return { items: await attachProducts(session, items), charges };
}

const getCartLines = async (session, orderID) => {

    const req = request.model(api.MODEL.C_ORDER_LINE)
        .select("C_OrderLine_ID","m_product_ID","m_attributeSetInstance_ID",
            "c_uom_ID","qtyEntered","lineNetAmt", "isBackOrder", "c_charge_ID")
        .expand("m_attributeSetInstance_ID")
        .expand("C_Charge_ID")
        .filter(condition.eq("C_Order_ID", orderID))
        .buildRequest(env.API_URL);

    const cartItemsResponse = await axios.get(req, api.auth(session))
    const cart = {};

    for(const line of cartItemsResponse.data.records) {
        const asi = line.M_AttributeSetInstance_ID;
        if(asi && asi.id > 0) {
            if(asi.received_asi_ID) {
                line.M_AttributeSetInstance_ID = { id: asi.received_asi_ID.id };
            } else if(asi.X_BaseASI_ID) {
                line.M_AttributeSetInstance_ID = { id: asi.X_BaseASI_ID.id };
            }
        }

        cart[line.id] = line;
    }

    return cart;
}

const getCharges = async (session, orderID) => {

    const req = request.model(api.MODEL.C_ORDER_LINE)
        .select("C_OrderLine_ID","m_product_ID","m_attributeSetInstance_ID",
            "c_uom_ID","qtyEntered","lineNetAmt", "isBackOrder", "c_charge_ID")
        .expand("m_attributeSetInstance_ID")
        .expand("C_Charge_ID")
        .filter(condition.eq("C_Order_ID", orderID))
        .filter(condition.greaterThan("C_Charge_ID", 0))
        .buildRequest(env.API_URL);

    const resp = await axios.get(req, api.auth(session))
    const charges = {};
    for(const line of resp.data.records) {
        charges[line.id] = line;
    }
    return charges;
}

const updateOrderLine = async (session, cartItem) => {
    const updateData = {
        "QtyEntered": cartItem.qtyEntered,
        "QtyOrdered": cartItem.qtyOrdered,
        "C_UOM_ID": {
            "id": cartItem.uomID,
        }
    }

    if(cartItem.isBackOrdered) {
        updateData["isbackorder"] = 'true';
    }

    if(cartItem.asiID > 0) {
        updateData["M_AttributeSetInstance_ID"] = {
            "id": cartItem.asiID
        }
    }

    const url = env.API_URL + API_ORDER_LINE + "/" + cartItem.orderLineID;
    const response = await axios.put(url, updateData, api.auth(session));

    const line = response.data;
    if(line.M_AttributeSetInstance_ID && line.M_AttributeSetInstance_ID.id > 0) {
        line.M_AttributeSetInstance_ID = { id: cartItem.asiID };
    }
    return response.data;
}

const createOrderLine = async (session, cartItem, cartInfo) => {
    const lineData = {
        "C_Order_ID": {
            "id": cartInfo.orderID,
        },
        "M_Product_ID": {
            "id": cartItem.productID,
        },
        "C_UOM_ID": {
            "propertyLabel": "UOM",
            "id": cartItem.uomID,
            "model-name": "c_uom"
        },
        "M_Warehouse_ID": {
            "propertyLabel": "Warehouse",
            "id": 1000000,
            "identifier": "Standard",
            "model-name": "m_warehouse"
        },
        "QtyEntered": cartItem.qtyEntered,
        "QtyOrdered": cartItem.qtyOrdered,
    }

    if(cartItem.isBackOrdered) {
        lineData["isbackorder"] = 'true';
    }

    if(cartItem.asiID > 0) {
        lineData["M_AttributeSetInstance_ID"] = {
            "id": cartItem.asiID
        }
    }

    const url = env.API_URL + API_ORDER_LINE;
    const response =  await axios.post(url, lineData, api.auth(session));

    if(cartItem.asiID > 0) {
        response.data.M_AttributeSetInstance_ID.id = cartItem.asiID;
    }

    return response.data;
}

const deleteOrderLine = async (session, orderLineID) => {
    const url = env.API_URL + API_ORDER_LINE + "/" + orderLineID;
    return await axios.delete(url, api.auth(session));
}

const processOrder = async (session, orderID, action) => {
    try {
        const completeIt = {"doc-action": action};
        const req = request.model(api.MODEL.C_ORDER)
            .id(orderID)
            .hostURL(env.API_URL)
            .buildRequest();

        const resp = await axios.put(req, completeIt, api.auth(session));
        return result.resultPass(resp);

    } catch (error) {
        if(error.response?.status === 401) {
            throw error;
        } else if (error.response) {
            return result.resultFailed({response: error.response});
        } else {
            return result.resultFailed({message: error.message});
        }
    }
}

const prepareTheOrder = async (session, orderID) => {
    return await processOrder(session, orderID, "PR");
}

const completeOrder = async (session, orderID) => {
    return await processOrder(session, orderID, "CO");
}

// eslint-disable-next-line
const invalidateOrder = async (session, orderID) => {
    try {
        const completeIt = {"docStatus": "IN"};
        const req = request.model(api.MODEL.C_ORDER)
            .id(orderID)
            .hostURL(env.API_URL)
            .buildRequest();

        const resp = await axios.put(req, completeIt, api.auth(session));
        return result.resultPass(resp);

    } catch (error) {
        if (error.response) {
            return result.resultFailed({response: error.response});
        } else {
            return result.resultFailed({message: error.message});
        }
    }
}

const updateCartLine = async (session, cartItem, cartInfo) => {
    if(cartItem.orderLineID === 0) {
        const res = await createOrderLine(session, cartItem, cartInfo);
        res["M_Product_ID"] = cartItem.product;
        return {deleted: false, ol:res};
    } else if(cartItem.qtyEntered > 0) {
        const res = await updateOrderLine(session, cartItem);
        res["M_Product_ID"] = cartItem.product;
        return {deleted: false, ol:res};
    } else {
        await deleteOrderLine(session, cartItem.orderLineID);
        return {deleted: true, olID: cartItem.orderLineID};
    }
}

export const getTheOrder = async (session, orderID) => {
    const req = request.model(api.MODEL.C_ORDER)
        .id(orderID)
        .expand(request.subQuery("C_BPartner_Location_ID").expand("C_Location_ID"))
        .hostURL(env.API_URL)
        .buildRequest();

    const response = await axios.get(req, api.auth(session));
    return response.data;

}

const getACart = async (session, dispatch, allowCreateCart = true) => {
    const cartID = await getExistingCartID(session);
    if(cartID > 0) {
        const contents = await getCartContents(session, dispatch, cartID);
        const cart = await getTheOrder(session, cartID);
        const items = contents.items ? contents.items : {};
        return {
            orderID: cartID,
            order: cart,
            items,
            charges: contents.charges
        }
    } else if(allowCreateCart) {
        const newCart = await createCart(session);
        return {
            orderID: newCart.id,
            order: newCart,
            items: {}
        }
    } else {
        return {
            orderID: 0,
            order: null,
            items: {}
        }
    }
}

const cartFetchRequired = (cart) => {
    return !cart || cart.DocStatus.id === "CO";
}

const addItemToCart = async (session, cartItem, getState, dispatch, forceFetchNewCart) => {
    const activeCart = getState().session.cart.order;
    const cartInfo = {};
    if(forceFetchNewCart || cartFetchRequired(activeCart)) {
        const newCart = await getACart(session, dispatch);
        cartInfo.orderID = newCart.orderID;
        cartInfo.newCart = newCart;
    } else {
        cartInfo.orderID = activeCart.id;
    }

    const onUpdated = await updateCartLine(session, cartItem, cartInfo);
    const updatedOrder = await getTheOrder(session, cartInfo.orderID);
    dispatch(getOrderDetails({session, orderID: cartInfo.orderID}))
    const charges = await getCharges(session, cartInfo.orderID);
    return {...onUpdated, order:updatedOrder, newOrder: cartInfo.newCart, charges };
}

const newCartRequired = (error) => {
    return error.response
        && error.response.data
        && error.response.data.detail
        && error.response.data.detail.startsWith("Save error with exception: Foreign ID");
}

const addItemToCartWithRetry = async (session, cartItem, getState, dispatch) => {
    try {
        return await addItemToCart(session, cartItem, getState, dispatch);
    } catch (error) {
        if(newCartRequired(error)) {
            return await addItemToCart(session, cartItem, getState, dispatch, true);
        } else {
            throw error;
        }
    }
}

const trackAddToCart = (order, cartItem, result) => {
    try {

        const event = result.ol? "add_to_cart" : "remove_from_cart";
        const value = result.ol?.LineNetAmt ? result.ol.LineNetAmt : 0;

        ReactGA.event(event, {
            currency: 'GBP',
            value,
            items: [{
                item_id: cartItem.product.value,
                item_name: cartItem.product.name,
                quantity: cartItem.qtyOrdered,
                item_category: cartItem.product.categoryID
            }]
        });
    } catch (error) {
        console.log("error occurred from tracking", error)
    }
}

const sendGetCartRequest = async (session, dispatch) => {
    return await getACart(session, dispatch, false);
}

export const getCart = createAsyncThunk(
    'cart/getCart',
    async ({session}, {rejectWithValue, dispatch}) => {

        try {
            return await runAuthRequest(session, (s) => sendGetCartRequest(s, dispatch), dispatch);
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)


const sendAddToCart = async (session, cartItem, getState, dispatch) => {
    const result = await addItemToCartWithRetry(session, cartItem, getState, dispatch);
    trackAddToCart(getState().session.cart.order, cartItem, result);
    return result;
}

export const addToCart = createAsyncThunk(
    'cart/add',
    async ({session, cartItem}, {rejectWithValue, getState, dispatch}) => {
        try {
            return await runAuthRequest(session, (s) => sendAddToCart(s, cartItem, getState, dispatch), dispatch);
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)

const sendGetTheOrder = async (session, orderID) => {
    return await getTheOrder(session, orderID);
}

export const getOrderDetails = createAsyncThunk(
    'cart/getOrderDetails',
    async ({session, orderID}, {rejectWithValue, dispatch}) => {
        try {
            console.log(orderID);
            return await runAuthRequest(session, (s) => sendGetTheOrder(s, orderID), dispatch);

        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)

const sendPrepareOrder = async (session, orderID, afterPrepare, rejectWithValue) => {
    const result = await prepareTheOrder(session, orderID);

    const runAfterPrepare = async () => {
        afterPrepare();
    }

    if(result.ok) {
        void runAfterPrepare();
    } else {
        const respData = result.value.response.data;
        const failureReason =  respData.detail.split("-").at(-1).trim();
        return rejectWithValue(failureReason)
    }
}

export const prepareOrder = createAsyncThunk(
    'cart/prepare',
    async ({session, orderID, afterPrepare}, {rejectWithValue, dispatch}) => {
        try {
            return await runAuthRequest(session, s => sendPrepareOrder(s, orderID, afterPrepare, rejectWithValue), dispatch);

        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }

)

const getProcessMessage = (detail) => {

    const creditLimitMsg = "Not enough credit to complete order @SO_CreditLimit@=";

    const asOne = detail.join();
    if(asOne.includes(creditLimitMsg)) {
        const msg = "Credit Limit Exceeded. Limit: ";
        const srvMsg = asOne.substring(asOne.indexOf(creditLimitMsg) + creditLimitMsg.length);
        return msg + srvMsg;
    } else {
        return detail.at(-1).trim();
    }
}

const sendCheckout = async (session, orderID, getState, dispatch, rejectWithValue) => {
    const result = await completeOrder(session, orderID);
    if(result.ok) {
        const state = getState().session;
        logPurchaseEvent(state.cart)
        dispatch(clearPayment());
        return {complete: true, order: result.value.data};
    } else {
        if(result.value.response && result.value.response.data) {
            const message = getProcessMessage(result.value.response.data.detail.split("-"));
            return rejectWithValue(message);
        } else {
            return rejectWithValue("Unknown error");
        }
    }
}

export const checkout = createAsyncThunk(
    'cart/checkout',
    async ({session, orderID}, {rejectWithValue, dispatch , getState}) => {
        try {
            return await runAuthRequest(session, s => sendCheckout(s, orderID, getState, dispatch, rejectWithValue), dispatch);
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)


export const logPurchaseEvent = (cart) => {

    try {

        console.log("cart lines", cart.lines);

        const items = Object.values(cart.lines).map(item => ({
            item_id: item.M_Product_ID.id,
            item_name: item.M_Product_ID.name,
            price: item.LineNetAmt,
            quantity: item.QtyEntered,
            item_category: item.M_Product_ID.categoryID
        }));

        ReactGA.event('purchase', {
            transaction_id: cart.order.DocumentNo,
            value: cart.order.TotalLines,
            currency: 'GBP',
            items: items
        });
    } catch (e) {
        console.log("Issue occurred with ga", e)
    }

    
};

const sendUpdateOrder = async (session, orderID, toChange) => {
    const req = request.model(api.MODEL.C_ORDER).id(orderID).buildRequest();
    const resp = await axios.put(env.API_URL + req, toChange, api.auth(session));
    const charges = await getCharges(session, orderID);
    return { data: resp.data, charges };
}

export const updateOrder = createAsyncThunk(
    'cart/updateOrder',
    async ({session, orderID, toChange}, {rejectWithValue, dispatch}) => {
        try {
            return await runAuthRequest(session, (s) => sendUpdateOrder(s, orderID, toChange), dispatch);
        } catch (error) {
            return api.catchError(dispatch, rejectWithValue, error, session);
        }
    }
)