import AddressDisplay from "../address/AddressDisplay";
import useBusinessLocations from "../../../../newStructure/services/state/account/useBusinessLocations";

const AccountLayoutLocations = () => {

    const { locations } = useBusinessLocations();

    const activeLocations = locations ? locations.filter(loc => loc.IsActive) : null;

    return (
        <>
            <h1>Locations</h1>
            <div className="account-content-container">
                <>
                    <div className="address-display-container">
                        {activeLocations && activeLocations.map((e,i) =>
                            <AddressDisplay key={i} location={e} >
                                {/*<Button className="button_clear" onClick={() => onEdit(e)}>Update</Button>*/}
                            </AddressDisplay>
                        )}
                    </div>
                    {/*<Button onClick={() => onEdit()}>Add New Address</Button>*/}
                </>
                {/*{editState.editing &&*/}
                {/*    <AddressInput*/}
                {/*        location={editState.location}*/}
                {/*        onSave={onAddressUpdate}*/}
                {/*        onBack={() => setEditState({editing: false})}*/}
                {/*        onInactivate={onInactivate}*/}
                {/*        updating={editState.location ? updatingLocs[editState.location.id] : updatingLocs[0]}*/}
                {/*    />*/}
                {/*}*/}
            </div>
        </>
    )

}

export default AccountLayoutLocations;
