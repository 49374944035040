import useUpdateEase from "../../newStructure/services/state/component/useUpdateEase";
import NavItem from "./NavItem";


const NavItemMenu = ({text, isCurrentLoc, displayState, onClick, children, className : passedClassName, ...props }) => {

    const {
        isHovering,
        onMouseEnter,
        onMouseLeave
    } = useUpdateEase();

    const passedClass = passedClassName ? passedClassName : "";
    const className = isHovering ? "hover" + passedClass : passedClass;

    return (
        <NavItem
            className={className}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            text={text}
            isCurrentLoc={isCurrentLoc}
            displayState={displayState}
            onClick={onClick}
            children={children}
            {...props}
        />
    )

}

export default NavItemMenu;