import HelpDeskItem from "../../../account/helpDesk/HelpDeskItem";
import useHelpDesk from "../../../../newStructure/services/state/helpDesk/useHelpDesk";


const getHelpDeskItem = (itemID, helpDeskItems) => {
    if(helpDeskItems && !isNaN(itemID)) {
        const compareID = Number(itemID);
        for(const category of helpDeskItems) {
            for(const item of category["web_help_desk_item"]) {
                if(item.id === compareID) {
                    console.log("hi")
                    return item;
                }
            }
        }
    }
}

const AccountLayoutHelpItem = ({itemID}) => {

    const { helpDeskItems } = useHelpDesk();
    const item = getHelpDeskItem(itemID, helpDeskItems);

    return (
        <HelpDeskItem item={item} />
    )

}

export default AccountLayoutHelpItem;
