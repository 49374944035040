import useRequestTracker from "../request/useRequestTracker";
import {useState} from "react";
import {idempiereDate} from "../../../../res/dataServices/idempiereDate";
import solrUpsellProductRequest from "../../rest/request/promotion/solrUpsellProductRequest";
import useValueChangeListener from "../general/useValueChangeListener";

const useWebUpsell = (params) => {

    const {
        loading,
        error,
        sendItWithTask
    } = useRequestTracker();

    const [ upsell, setUpsell ] = useState([]);

    const afterStart = (upsellItem) => {
        const startDate = upsellItem.startDate;
        if(startDate) {
            const now = new Date();
            return idempiereDate.getDate(startDate) < now;
        }
        return false;
    }

    const beforeEnd = (upsellItem) => {
        const endDate = upsellItem.endDate;
        if(endDate) {
            const now = new Date();
            return idempiereDate.getDate(endDate) > now;
        }
        return false;
    }

    const filterItem = (upsellItem) => {
        return afterStart(upsellItem) && beforeEnd(upsellItem);
    }

    const onUpsellReceived = (res) => {
        const upsellItems = [...res].filter(filterItem)
        const sorted = upsellItems.sort((a,b) => a.seqNo - b.seqNo);
        setUpsell(sorted);
    }

    const fetchUpsell = () => {
        const req = solrUpsellProductRequest();
        if(params?.categoryID) req.setCategoryID(params.categoryID);
        sendItWithTask(() => req.sendRequest(), onUpsellReceived);
    }

    useValueChangeListener(fetchUpsell, [params], []);

    return {
        loading,
        error,
        promotions: upsell
    }

}

export default useWebUpsell;