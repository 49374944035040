import {logOut} from "../../redux/slice/authSlice";
import {env} from "../config/env";
import axios from "axios";
import {reportError} from "../../redux/slice/errorReportSlice";
import errorReportRequest from "../../newStructure/services/rest/request/error/errorReportRequest";
import ErrorReport from "../../newStructure/logic/error/ErrorReport";

const isUnauthorized = (error) => {
    return error
        && error.response
        && error.response.status === 401
}

const auth = (session) => {

    const token = session && session.authToken ? session.authToken : env.API_KEY;

    if(token) {
        return {
            headers: {
                Authorization: `Bearer ` + token
            }
        }
    } else {
        return {headers: {}};
    }
}

const getAllPages = async (getAPage) => {
    const page0 = await getAPage(0);
    const pageCount = page0["page-count"];
    const pageSize  = page0["records-size"]

    let records = page0.records;

    for(let i = 1; i < pageCount; i++) {
        const nextPage = await getAPage(i * pageSize);
        records = records.concat(nextPage.records)
    }

    return records;
}

const getAllRequestPages = async (requestBuilder, session) => {

    const getAPage = async (offset) => {
        const req = requestBuilder
            .skip(offset)
            .buildRequest(env.API_URL);

        const result = await axios.get(req, auth(session));
        return result.data;
    }

    return await getAllPages(getAPage);
}

const isServerError = (error) => {
    if(error?.message === "Network Error") {
        return true
    } else if(error.response) {
        const status = error.response.status;
        return status >= 500 && status <= 500;
    }
    return false;
}

const getErrorResponse = (error) => {

    if (error.response && error.response.data) {
        const detail = error.response.data.detail;

        if(isServerError(error) && detail) {
            const messageArr = error.response.data.detail.split(":");
            return messageArr.at(-1).trim();
        }

        if(detail) {
            return detail;
        } else if (error?.response?.data?.message) {
            return error.response.data.message
        }
    }

    if(error.message)
        return error.message
    else
        return "Unknown error"

}

const reportApiError = async (tag, error, session) => {
    const here = await ErrorReport.getStackTrace(new Error());
    const errorMessage = await ErrorReport.buildErrorMessage(error);
    const message = errorMessage + "\n\nOccurred At " + here;

    void errorReportRequest(session)
        .tag(tag)
        .message(message)
        .url(window.location.href)
        .sendIt(1);
}

export const api = {

    MODEL: {
        C_BPARTNER: "c_bpartner",
        AD_USER: "ad_user",
        AD_ORG: "ad_organization",
        BP_LOCATION: "c_bpartner_location",
        C_LOCATION: "c_location",
        M_PRODUCT_CATEGORY: "m_product_category",
        C_ORDER: "c_order",
        C_ORDER_LINE: "c_orderline",
        C_SALES_REGION: "c_salesregion",
        M_INOUT: "m_inout",
        M_INOUT_LINE: "m_inoutline",
        C_INVOICE: "c_invoice",
        C_INVOICE_LINE: "c_invoiceLine",
        DRIVERS: "suthbros_driversavailable",
        DRIVER_LOCATION: "x_driver_app_location",
        WEB_INVOICE_LINE: "web_ordering_invoiceLine_v",
        WEB_SHIPMENT_LINE: "web_ordering_shipmentLine_v",
        M_PRODUCT: "m_product",
        USUALS: "sales_bpartner_usuals_v",
        USUALS_RANKED: "sales_bpartner_usuals_ranked",
        WEB_FILTERS: "web_filter_attribute_v",
        UPSELL_PRODUCTS: "x_upsell_product",
        TOP_PRODUCTS: "x_topsellers",
        HELP_DESK_CATEGORY: "web_help_desk_category",
        HELP_DESK_ITEM: "web_help_desk_item",
        REQUEST: "r_request",
        BRAND_BANK: "web_brandBankdetail_v",
        DISCOUNT: "web_product_discount_v",
        PRICE_PROMOTION: "price_bp_price_promotion_v",
        BANK_ACCOUNT: "c_bp_bankaccount",
        BACK_ORDER: "stock_bo_status_v"
    },

    PROCESS : {
        PAYMENT_HANDLER: "handleauthrequest",
        PAYMENT_PAYER_AUTH_SETUP: "setupcompletion",
        PAYMENT_ENROLL_AUTH: "enrollwithpendingauthentication",
        PAYMENT_VALIDATE_AUTH_RES: "validateauthenticationresults",
        BACK_ORDER_CANCEL: "closeorderline",
        REQUEST_PASSWORD_RESET: "createresetpasswordlink",
        RESET_PASSWORD: "resetpassword"
    },

    auth,



    catchError : (dispatch, rejectWithValue, error, session) => {

        if(isUnauthorized(error)) {
            dispatch(logOut());
            return rejectWithValue("Session Expired")
        }

        if(!isServerError(error)) {
            void reportApiError("API Error", error, session)
        }

        const response = getErrorResponse(error);
        if(response && isServerError(error)) {
            dispatch(reportError({code: response, error: response}));
        }

        return rejectWithValue(response);
    },

    recordsToMap : (records) => {
        const map = {};
        records.forEach(po => map[po.id] = po);
        return map;
    },

    getAllPages,
    getAllRequestPages,

}