
const filterTransform = (list, filter, transform) => {
    const newList = [];
    for(const item of list) {
        if(filter(item))
            newList.push(transform(item));
    }
    return newList;
}

const forYield = (list, forEach) => {
    if(!list) return null;
    const newList = [];
    for(const item of list) {
        forEach(item, (i) => newList.push(i));
    }
    return newList;
}

const ListUtil = {
    filterTransform,
    forYield
}

export default ListUtil;

