import useMultiRequestTracker from "./useMultiRequestTracker";
import useSession from "../session/useSession";
import {useDispatch} from "react-redux";
import runAuthRequest from "../../../../redux/util/runAuthRequest";

const useMultiAuthRequest = () => {

    const {
        trackedRequests,
        getStatus,
        sendIt
    } = useMultiRequestTracker();

    const session = useSession();
    const dispatch = useDispatch();

    const addAuthWrapper = (request) => () => {
        return runAuthRequest(session.sessionData, s => request(s), dispatch)
    }

    const authSendIt = (key, request, onSuccess) => {
        sendIt(key, addAuthWrapper(request), onSuccess);
    }

    return {
        session,
        getStatus,
        trackedRequests,
        sendIt: authSendIt
    }

}

export default useMultiAuthRequest;
