import useRequestTracker from "../request/useRequestTracker";
import requestPasswordResetRequest from "../../rest/request/account/requestPasswordResetRequest";
import resetPasswordRequest from "../../rest/request/account/resetPasswordRequest";

const usePasswordManager = () => {

    const {
        loading,
        error,
        sendIt,
        result
    } = useRequestTracker();

    const requestPasswordReset = (email) => {
        const req = requestPasswordResetRequest()
            .email(email);

        sendIt(req.sendIt);
    }

    const resetPassword = (token, password) => {
        const req = resetPasswordRequest()
            .token(token)
            .password(password);

        sendIt(req.sendIt);
    }


    return {
        loading,
        error,
        result,
        resetPassword,
        requestPasswordReset
    }

}

export default usePasswordManager; 