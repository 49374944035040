import {useNavigate} from "react-router-dom";
import '../style/promotion/advert.css'

const Leaderboard = ({ imageUrl, link }) => {

    const navigate = useNavigate();
    const onClick = () => {
        navigate(link);
    }


    return (
        <div
            className="leaderboard"
            onClick={onClick}
        >
            <img alt="" src={imageUrl} />
        </div>
    )

}

export default Leaderboard;
