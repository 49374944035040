import FilterContainer from "../../general/filter/FilterContainer";

const ProductOptions = () => {

    return (
        <FilterContainer header="Product Options">
            <p style={{
                textAlign: "left",
                fontWeight: "500",
                textDecoration: "underline",
                color: "var(--secondary)"
            }}>List new product</p>
        </FilterContainer>
    )

}

export default ProductOptions;
