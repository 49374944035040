import TextArea from "../../../components/general/input/TextArea";
import {Button} from "../../../components/general/input/Button";
import {useState} from "react";
import useCreateHelpDeskRequest from "../../services/state/helpDesk/useCreateRequest";
import '../style/helpDesk/helpDeskCreate.css'

const DEFAULT_SUCCESS_MESSAGE = "Thank You. A ticket has been created, " +
    "which we will review and respond as soon as possible."

const HelpDeskRequestInput = ({ requestTypeID, submitText="Send", afterSentMessage=DEFAULT_SUCCESS_MESSAGE, description }) => {

    const [ userInput, setUserInput ] = useState("");
    const [ warning, setWarning ] = useState("");

    const {
        loading,
        createRequest,
        sent
    } = useCreateHelpDeskRequest();

    const onSubmit = () => {
        if(loading) return;
        if(!userInput || userInput === "") {
            setWarning("Description cannot be blank");
        } else {
            createRequest({
                requestTypeID: requestTypeID,
                summary: userInput
            });
        }
    }

    if(sent) {
        return (
            <div className="help-desk-request-input-container">
                <p className="help-desk-request-success">{afterSentMessage}</p>
            </div>
        )
    } else {
        return (
            <div className="help-desk-request-input-container">
                {description && <p>{description}</p>}
                {warning &&
                    <p className="help-desk-warning">{warning}</p>
                }
                <TextArea
                    onChange={e => setUserInput(e.target.value)}
                />
                <Button
                    onClick={onSubmit}
                    className={loading ? "button-loading" : ""}
                >
                    {submitText}
                </Button>
            </div>
        )
    }
}

export default HelpDeskRequestInput;