import PageLayout from "./PageLayout";
import useWebUpsell from "../../newStructure/services/state/promotion/useWebUpsell";
import PromotionCreator from "../../newStructure/component/promotion/PromotionCreator";

const PageTest = () => {

    useWebUpsell({categoryID: 1000048});

    return (
        <PageLayout>
            <PromotionCreator />
            {/*<div>*/}
            {/*    <Button*/}
            {/*        style={{*/}
            {/*            height: "300px",*/}
            {/*            width: "70vw",*/}
            {/*            margin: "100px auto",*/}
            {/*            textAlign: "center",*/}
            {/*            display: "flex"*/}
            {/*        }}*/}
            {/*        onClick={runTest}>*/}
            {/*        <p style={{*/}
            {/*            margin: "auto auto",*/}
            {/*            fontSize: "10em"*/}
            {/*        }}>Test</p>*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </PageLayout>
    )

}

export default PageTest;