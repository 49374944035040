import {env} from "../../res/config/env";
import axios from "axios";
import {API_REFRESH} from "../action/authActions";
import {logOut, setRefreshToken} from "../slice/authSlice";

const runRefresh = async (refresh_token, dispatch) => {

    try {
        const url = env.API_URL + API_REFRESH;
        const result = await axios.post(url, {refresh_token})
        dispatch(setRefreshToken(result.data));
        return result.data.token;
    } catch (e) {
        const statusCode = e.response?.status;
        if(statusCode >= 400 && statusCode < 501) {
            console.log("logging out")
            dispatch(logOut())
        }
        throw e;
    }
}

const attemptMap = {};

const attemptLoginRefresh = (refreshToken, dispatch) => {

    const attempt = attemptMap[refreshToken];
    if(!attempt) {
        attemptMap[refreshToken] = runRefresh(refreshToken, dispatch);
        return attemptMap[refreshToken];
    } else {
        return attempt;
    }

}

export default attemptLoginRefresh;