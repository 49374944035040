import backOrderRequest from "../../rest/request/account/backOrderRequest";
import useMultiAuthRequest from "../request/useMultiAuthRequest";
import {useState} from "react";
import cancelBackOrderRequest from "../../rest/request/account/cancelBackOrderRequest";

const FETCH = "fetch";

const useBackOrders = () => {

    const {
        session,
        getStatus,
        trackedRequests,
        sendIt
    } = useMultiAuthRequest();
    const [ backOrderLines, setBackOrderLines ] = useState();

    const getBackOrders = () => {
        const req = backOrderRequest();
        sendIt(FETCH, req.sendIt, setBackOrderLines);
    }

    const { loading, error } = getStatus(FETCH);
    if(session.isSignedIn && !loading && !error && !backOrderLines) {
        getBackOrders();
    }

    const cancelBackOrder = (orderLineID) => {
        const req = cancelBackOrderRequest(orderLineID);
        sendIt(orderLineID, req.sendIt, () => getBackOrders());
    }

    return {
        loading,
        error,
        backOrderLines,
        cancelBackOrder,
        getStatus,
        trackedRequests
    }

}

export default useBackOrders;