import {useState} from "react";
import {useDispatch} from "react-redux";
import {reportError} from "../../../../redux/slice/errorReportSlice";
import ErrorReport from "../../../logic/error/ErrorReport";
import runAuthRequest from "../../../../redux/util/runAuthRequest";
import useSession from "../session/useSession";

const useAuthRequest = () => {

    const dispatch = useDispatch();
    const [ requestStatus, setRequestStatus ] = useState({});
    const session = useSession();
    const {
        loading,
        error,
        result
    } = requestStatus;

    const displayErrorReport = (error) => {
        const code = crypto.randomUUID();
        const errorMsg = ErrorReport.getUserMessage(error);
        dispatch(reportError({ code: code, error: errorMsg }));
    }

    const beforeRequest = () => {
        setRequestStatus({
            loading: true,
            error: null,
            result: null,
            pendingRefresh: null,
        })
    }

    const afterFailure = (error) => {
        setRequestStatus({
            loading: false,
            error,
            result: null,
            pendingRefresh: null,
        });
        displayErrorReport(error);
    }

    const afterSuccess = (result, optionalTask) => {
        setRequestStatus({
            loading: false,
            error: false,
            result,
            pendingRefresh: null,
        });

        if(optionalTask)
            optionalTask(result);
    }

    const sendRequest = async (request, optionalTask) => {
        try {
            const result = await runAuthRequest(session?.sessionData, s => request(s), dispatch);
            afterSuccess(result, optionalTask);
        } catch (error) {
            afterFailure(error);
        }
    }

    const sendIt = (request, optionalTask) => {
        beforeRequest();
        void sendRequest(request, optionalTask);
    }

    return {
        session,
        loading,
        error,
        result,
        sendIt
    }

}

export default useAuthRequest;