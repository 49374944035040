import Header from "../header/header/Header";
import Footer from "../footer/Footer";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import GeneralError from "../general/error/GeneralError";
import ErrorReport from "../error/ErrorReport";
import LoginRequired from "../auth/LoginRequired";
import useStoredBreadcrumb from "../../newStructure/services/state/navigation/useStoredBreadcrumb";
import {ErrorBoundary} from "../general/error/ErrorBoundary";

const PageError = () => {
    return (
        <div className="body">
            <GeneralError />
        </div>
    )
}

const PageLayout = ({ requireSignIn, children, headerChildren }) => {

    const [ firstLoad, setFirstLoad ] = useState(true);

    const { session } = useSelector(
        state => state.local.auth
    )

    if(firstLoad) {
        setFirstLoad(false)
        window.scrollTo(0,0);
    }

    const displaySignIn = requireSignIn && (!session || !session.bPartnerID);

    const {
        storeBreadcrumb
    } = useStoredBreadcrumb();

    useEffect(() => {
        const hooverCrumbs = () => storeBreadcrumb(null);
        window.addEventListener("beforeunload", hooverCrumbs);
        return () => {
            window.removeEventListener("beforeunload", hooverCrumbs);
        };
    }, [storeBreadcrumb]);

    return (
        <>
            <Header>
                {headerChildren}
            </Header>
            <ErrorBoundary
                fallBack={<PageError/>}
                session={session}
            >
                {displaySignIn ?
                    <LoginRequired />
                    : <>{children}</>
                }
                <ErrorReport />
            </ErrorBoundary>
            <Footer/>
        </>
    )
}

export default PageLayout;