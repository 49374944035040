import MapUtil from "../collection/mapUtil";

const EMPTY_FILTER = "all";

const UrlFormat = {
    and: {url: "|and|", char: "&"},
    slash: {url: "|slash|", char: "/"}
}

const formatName = (name) => {
    return Object.values(UrlFormat)
        .reduce((url, format) => url.replaceAll(format.char, format.url), name);
}

const reverseFormat = (name) => {
    return Object.values(UrlFormat)
        .reduce((url, format) => url.replaceAll(format.url, format.char), name);
}

const joinAttributeToUrl = (key, value, url) => {
    const nameStr = value.reduce((str,name) => {
        const formatted = formatName(name);
        if(str) return str + "," + formatted;
        else return formatted;
    }, "")

    const attrStr = key + "=" + nameStr;

    if(url) return url + "&" + attrStr
    else return attrStr;
}

const getUrl = (map) => {
    if(!map || Object.keys(map).length === 0) {
        return EMPTY_FILTER;
    }
    return MapUtil.reduce(map, joinAttributeToUrl, "");
}

const getMap = (url) => {
    if(!url || url === EMPTY_FILTER) return {};

    const map = {};
    for(const filter of url.split("&")) {
        const [attributeID, selectedValues] = filter.split("=");
        if(!attributeID || !selectedValues) continue;
        map[attributeID] = selectedValues.split(",").map(n => reverseFormat(n));
    }
    return map;
}

const FilterUrl = {
    getUrl,
    getMap,
}

export default FilterUrl;