import Popup from "../general/popup/Popup";
import {Button} from "../general/input/Button";

const BackOrderPrompt = ({ webProduct, desiredQuantity, desiredUom, onResponse }) => {

    const uom = desiredUom ? desiredUom : webProduct.getSelectedUom();
    const adjustedAvailable = Math.floor(webProduct.getAvailable() / uom.uomSize);
    const uomDescription = uom.name.includes("Case") ?
        "case" + (adjustedAvailable > 0 ? "s" : "")
        : uom.name.includes("Split") ? "split" + (adjustedAvailable > 0 ? "s" : "") : uom.name;
    const toBeBackOrdered = desiredQuantity - adjustedAvailable;

    const getOOSBackOrderTitle = () => {
        if(webProduct.isNotStocked()) {
            return "This product is only available on request, do you want to back order it?";
        } else {
            return "This product is currently out of stock, do you want to back order it?";
        }
    }

    const getOOSBackOrderMessage = () => {

        if(webProduct.isNotStocked()) {
            return <>This product is only available on request. If you place the stock on back order, we will order it
                in for you. Would you like to place this product on back order?</>
        } else {
            return <>There are currently only <b>{adjustedAvailable}</b> {uomDescription} available,
                would you like to place the additional <b>{toBeBackOrdered}</b> on back order?</>
        }
    }

    return (
        <div className="back-order-prompt">
            <h1>Secure Your Items with Back Order!</h1>
            <p className="back-order-message">
                {webProduct.getAvailable() <= 0 ?
                    <>{getOOSBackOrderTitle()}</> :
                    <>{getOOSBackOrderMessage()}</>
                }
            </p>
            <h3>Back Order? Here's How it Works:</h3>
            <p><b>Secure Your Products:</b> Lock in your position in the queue for future stock.</p>
            <p><b>Shipping:</b> Once stock becomes available, we will deliver it with your next order, or if your
                back orders meet the minimum shipment amount, we will deliver them as a standalone delivery.</p>
            <div className="back-order-button-container">
                <Button className="button_clear" onClick={() => onResponse(false)}>Back</Button>
                <Button onClick={() => onResponse(true)}>Back Order</Button>
            </div>
        </div>
    )
}

const CantOrderPrompt = ({ webProduct, desiredQuantity, onClose }) => {

    const uom = webProduct.getSelectedUom();
    const adjustedAvailable = Math.floor(webProduct.getAvailable() / uom.uomSize);
    const uomDescription = uom.name.includes("Case") ? "case" + (adjustedAvailable > 0 ? "s" : "") : uom.name;

    return (
        <div className="back-order-prompt">
            <h1>Insufficient Stock</h1>
            <p className="back-order-message">
                {desiredQuantity > 1 ?
                    <>There are currently only <b>{adjustedAvailable}</b> {uomDescription} available. Unfortunately
                        this product cannot currently be back ordered</> :
                    <>Unfortunately you cannot currently back order this product</>
                }
            </p>
            <div className="back-order-button-container">
                <Button style={{gridColumn: "2"}} onClick={onClose}>Back</Button>
            </div>
        </div>
    )
}

const InsufficientStockPrompt = ({ webProduct, desiredQuantity, desiredUom, canBackOrder, onResponse }) => {

    return (
        <Popup>
            {canBackOrder ?
                <BackOrderPrompt
                    webProduct={webProduct}
                    desiredQuantity={desiredQuantity}
                    desiredUom={desiredUom}
                    onResponse={onResponse} /> :
                <CantOrderPrompt
                    webProduct={webProduct}
                    desiredQuantity={desiredQuantity}
                    onClose={() => onResponse(false)} />
            }
        </Popup>
    )

}

export default InsufficientStockPrompt;