import React from "react";
import PageLayout from "../../../../components/page/PageLayout";
import DeliveryCountDown from "../../../../components/checkout/DeliveryCountDown";
import SignInBannerWithState from "../../auth/SignInBannerWithState";
import LandingLayout from "../../home/LandingLayout";

const PageHome = () => {

    return (
        <PageLayout>
            <SignInBannerWithState />
            <LandingLayout />
            <DeliveryCountDown />
        </PageLayout>
    )
}

export default PageHome;