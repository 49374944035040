import attemptLoginRefresh from "./refreshLoginController";
import {logOut} from "../slice/authSlice";

const isUnauthorized = (error) => {
    return error
        && error.response
        && error.response.status === 401
}

const runAuthRequest = async (session, request, dispatch) => {
    try {
        return await request(session);
    } catch (e) {
        if(isUnauthorized(e)) {
            if(session.refreshToken) {
                const authToken = await attemptLoginRefresh(session.refreshToken, dispatch);
                const updatedSession = {
                    ...session,
                    authToken
                }
                return await request(updatedSession);
            } else {
                dispatch(logOut());
            }
        } else {
            console.log("here")
            throw e;
        }
    }
}

export default runAuthRequest;