import {combineReducers, configureStore } from '@reduxjs/toolkit'
import {persistReducer, persistStore} from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage'
import authReducer from './slice/authSlice'
import cartReducer from './slice/cartSlice'
import categoryReducer from './slice/categorySlice'
import paymentReducer from './slice/paymentSlice'
import errorReportReducer from './slice/errorReportSlice'
import {errorReset, sessionTimeOutReset} from "./setup/cleanup";

const localPersistConfig = {
    key: 'local',
    storage,
    transforms: [errorReset, sessionTimeOutReset]
}

const sessionPersistConfig = {
    key: 'session',
    storage: storageSession,
    transforms: [errorReset],
    whitelist: ['cart', 'category']
};


const sessionReducer = combineReducers({
    cart: cartReducer,
    category: categoryReducer,
    payment: paymentReducer,
    errorReport: errorReportReducer,
})

const localReducer = combineReducers({
    auth: authReducer
})

const sessionPersistedReducer = persistReducer(sessionPersistConfig, sessionReducer);
const localPersistedReducer = persistReducer(localPersistConfig, localReducer);

const persistedReducer = combineReducers({
    session: sessionPersistedReducer,
    local: localPersistedReducer
})

export const store = configureStore({
    reducer: persistedReducer,
});


export const persistor = persistStore(store);
