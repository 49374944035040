import {createSlice} from '@reduxjs/toolkit';
import {login, refreshLogin} from "../action/authActions";

const initialState = {
    loading: false,
    error: null,

    session: null,
    rememberMe: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setBusinessPartner: ( state, { payload }) => {
            const updatedSession = {...state.session};
            updatedSession.bPartnerID = payload.id;
            updatedSession.businessName = payload.Name;
            updatedSession.businessCode = payload.Value;
            updatedSession.canBackOrder = payload["X_Allow_Back_Order"];
            updatedSession.priceListID = payload["M_PriceList_ID"]?.id
            state.session = updatedSession;
        },
        logOut: ( state ) => {
            state.session = null;
        },
        setRememberMe: ( state, {payload}) => {
            state.rememberMe = payload.isChecked;
        },
        setRefreshToken: (state, { payload }) => {
            state.session = {
                ...state.session,
                authToken: payload.token,
                refreshToken: payload.refresh_token
            }
        }
    },
    extraReducers: {
        [login.pending]: (state) => {
            state.loading = true
            state.error = null
        },
        [login.fulfilled]: (state, { payload }) => {
            state.loading = false

            if(payload.orgs) {
                state.orgSelect = payload;
            } else {

                state.orgSelect = null;

                const refreshToken = state.rememberMe ? payload.refreshToken : "";
                state.session = {
                    ...payload,
                    refreshToken
                };
            }
        },
        [login.rejected]: (state, { payload }) => {
            console.log("slice error received")
            state.loading = false
            state.error = payload
        },
        [refreshLogin.pending]: (state) => {
            state.refreshing = true
            state.refreshError = null
        },
        [refreshLogin.fulfilled]: (state, { payload }) => {
            state.refreshing = false;
        },
        [refreshLogin.rejected]: (state, { payload }) => {
            state.refreshing = false
            state.refreshError = payload
        },
    }
})

export const { setBusinessPartner, logOut, setRememberMe, setRefreshToken } = authSlice.actions;
export default authSlice.reducer;