import '../../style/vendor/productEditor.css'
import React, {useState} from "react";
import VendorProductTile from "./VendorProductTile";

const ProductEditor = ({ product }) => {

    const [ image, setImage ] = useState(null);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="product-editor-container">
            <div className="product-editor-header-container">
                <p className="prod-detail-value">{product.value}</p>
                <p className="prod-detail-name">{product.name}</p>
            </div>
            <div className="product-editor-img-editor">
                <p style={{gridArea: "img-header"}}>Product Detail Page</p>
                <img style={{gridArea: "img"}} alt="" src={product.imageUrl}/>
                <p style={{gridArea: "tile-header"}}>Tile</p>
                <VendorProductTile
                    product={product}
                    style={{gridArea: "tile"}}
                    hideMore
                />
                <label style={{gridArea: "edit"}} className="edit-text">
                    Upload New Image
                    <input type="file" accept="image/*" onChange={handleImageUpload}/>
                </label>
                {image &&
                    <>
                        <img style={{gridArea: "new-img"}} alt="" src={image} />
                        <VendorProductTile
                            product={product}
                            style={{gridArea: "new-tile"}}
                            hideMore
                            altImg={image}
                        />
                        <div style={{gridArea: "save"}}>
                            <p className="edit-text">Save</p>
                        </div>

                    </>
                }

            </div>
        </div>
    )

}

export default ProductEditor;