import useAuthRequest from "../request/useAuthRequest";
import helpDeskRequest from "../../rest/request/helpDesk/helpDeskRequest";

const useHelpDesk = () => {

    const {
        session,
        loading,
        error,
        sendIt,
        result
    } = useAuthRequest();

    if(session.isSignedIn && !loading && !error && !result) {
        const req = helpDeskRequest();
        sendIt(req.sendIt);
    }

    return {
        loading,
        error,
        helpDeskItems: result
    }

}

export default useHelpDesk;