import PageLayout from "../../../../components/page/PageLayout";
import {Breadcrumb, BreadcrumbNavigation} from "../../navigation/Breadcrumb";
import Pagination from "../../navigation/Pagination";
import ProductFilter from "../../product/ProductFilter";
import ProductLayout from "../../product/ProductLayout";
import useProductIDPage from "../../../services/state/product/useProductIDPage";
import {useParams} from "react-router-dom";
import usePageBreadcrumbTracker from "../../../services/state/navigation/usePageBreadcrumbTracker";
import useSolrFilters from "../../../services/state/product/useSolrFilters";
import FilterUrl from "../../../logic/filter/filterUrl";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import OrderBy from "../../../logic/filter/orderBy";

const getFilterUrl = (filter, filterParam) => {
    if(filter) {
        return "/" + filter;
    } else if(filterParam) {
        return "/" + filterParam;
    } else {
        return "";
    }
}

const PageProductID = ({ requiresSignIn=true, loading, breadcrumbTrail, productIDs, getProductList, onPageChange, getNav }) => {

    const { pageNo : pageNoParam, filter : filterParam } = useParams();

    const pageNo = pageNoParam && !isNaN(Number(pageNoParam)) ? Number(pageNoParam) : 1;
    useValueChangeListener(() => window.scrollTo(0,0), [ pageNo, filterParam ]);

    const {
        getFilters,
        filters
    } = useSolrFilters();

    const {
        loading: productsLoading,
        products,
        pageCount,
    } = useProductIDPage(pageNo, productIDs, filterParam);

    const handlePageChange = (pageNo, filter) => {
        onPageChange(pageNo, filter ? filter : "");
    }

    const onPageChangeRequest = (pageNo) => {
        handlePageChange(pageNo, "/" + filterParam);
    }

    const onFilterApply = (selectedFilters) => {
        const filter = FilterUrl.getUrl(selectedFilters);
        handlePageChange(1, getFilterUrl(filter, filterParam));
    }

    const requestFilters = () => {
        if(productIDs && productIDs.length > 0) {
            getFilters({productIDs});
        }
    }

    const filtersWithOrdering = filters ? {...filters, [OrderBy.ATTRIBUTE_SET.id]: OrderBy.FILTER} : null;
    // usePageBreadcrumbTracker(breadcrumbTrail, [pageNo, filterParam], () => `/clearance/${pageNo}`);
    usePageBreadcrumbTracker(breadcrumbTrail, [pageNo, filterParam], () => getNav ? getNav(pageNo, filterParam) : null);
    useValueChangeListener(requestFilters, productIDs);

    return (
        <PageLayout
            requireSignIn={requiresSignIn}
        >
            <div className="page-content product-content">
                <BreadcrumbNavigation>
                    {breadcrumbTrail.map((crumb, i) =>
                        <Breadcrumb
                            key={i}
                            name={crumb.name}
                            nav={crumb.nav}
                            selected={crumb.selected}
                        />
                    )}
                </BreadcrumbNavigation>
                <ProductLayout>
                    <div className="product-layout-filters">
                        <ProductFilter
                            filters={filtersWithOrdering}
                            onApply={onFilterApply}
                            defaultParam={filterParam}
                        />
                    </div>
                    {getProductList(products, productsLoading || loading)}
                </ProductLayout>
                <Pagination
                    currentPage={pageNo}
                    lastPage={pageCount - 1}
                    onPageRequested={onPageChangeRequest}
                    loading={productsLoading || loading}
                />
            </div>
        </PageLayout>
    )
}

export default PageProductID;
