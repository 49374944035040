import useRequestTracker from "../request/useRequestTracker";
import searchRequest from "../../rest/request/search/searchRequest";
import {useSelector} from "react-redux";

const useSearchAll = () => {

    const {
        loading,
        result,
        sendIt
    } = useRequestTracker();

    const { session } = useSelector(
        state => state.local.auth
    )

    const search = (term, maxResults=1000) => {
        if(!term || term.length < 3) return;
        const req = searchRequest()
            .setPageSize(maxResults)
            .setSearchTerm(term)
            .setPriceList(session?.priceListID);

        sendIt(req.sendRequest);
    }

    return {
        loading,
        searchTerm: result?.searchTerm,
        searchResults: result?.searchResults,
        searchCount: result?.count,
        search
    }

}

export default useSearchAll;