
const findCategoryInTree = (categoryID, categories) => {
    // console.log("looking in tree", categories, "for", categoryID);
    if(!categories) {
        return null;
    } else {
        for(const category of categories) {
            if(categoryID === category.id) {
                return category;
            } else {
                const childMatch = findCategoryInTree(categoryID, category.children);
                if(childMatch) return childMatch;
            }
        }
        return null;
    }
}

const findCategory = (categoryMap, categoryID) => {
    if(!categoryMap) {
        return null;
    } else if(categoryID in categoryMap) {
        return categoryMap[categoryID];
    } else {
        return findCategoryInTree(categoryID, Object.values(categoryMap));
    }
}

const getCrumb = (category, selectedID) => {
    return {
        name: category.name,
        nav: "/category/" + category.id,
        selected: category.id === selectedID
    }
}

const getBreadcrumbTrail = (categoryMap, categoryID, selectedID, crumbs = []) => {
    const category = findCategory(categoryMap, categoryID);
    if(category != null) {
        const parentId = category.parentID;
        const crumb = getCrumb(category, selectedID);
        const childCrumbs = getBreadcrumbTrail(categoryMap, parentId, selectedID, crumbs);
        childCrumbs.push(crumb);
        return childCrumbs;
    } else {
        return crumbs;
    }
}

const breadcrumb = {
    getBreadcrumbTrail,
    findCategory
}

export default breadcrumb;