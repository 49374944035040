import {env} from "../../../../../res/config/env";
import Solr from "../../../../logic/search/solr";
import axios from "axios";
import ReactGA from 'react-ga4';


const searchRequest = () => {

    const builder = {};

    builder.setSearchTerm = (searchTerm) => {
        builder.searchTerm = searchTerm;
        return builder;
    }

    const getSearchUrl = (input, pageNo, pageSize, priceListID) => {
        const term = Solr().search(input, pageNo, pageSize, priceListID).replaceAll(" ", "%20");
        return env.API_SOLR_URL + term;
    }

    const getSolrResults = async(searchUrl) => {
        const response = await axios.get(searchUrl);
        return response.data.response;
    }

    builder.setPageNo = (pageNo) => {
        builder.pageNo = pageNo;
        return builder;
    }

    builder.setPageSize = (pageSize) => {
        builder.pageSize = pageSize;
        return builder;
    }

    builder.setPriceList = (priceListID) => {
        builder.priceListID = priceListID;
        return builder;
    }

    builder.sendRequest = async () => {

        const searchUrl = getSearchUrl(builder.searchTerm, builder.pageNo, builder.pageSize, builder.priceListID);
        const searchResults = await getSolrResults(searchUrl);

         // Log search to Google Analytics
         ReactGA.event({
            category: 'Search',
            action: 'Performed Search',
            label: builder.searchTerm,
            value: searchResults.numFound
        });
        return {
            searchTerm: builder.searchTerm,
            searchResults: searchResults.docs,
            count: searchResults.numFound
        };
    }

    return builder;

}

export default searchRequest;