import SolrField from "./solrField";


const getUomSize = (uomName) => {
    if(uomName.startsWith("Case of")) {
        const size = uomName.slice(8)
        return Number(size);
    } else {
        return 1;
    }
}

const formatProduct = (rawProduct) => {

    const formattedProduct = {};
    const { getValue } = SolrField;

    formattedProduct.id             = getValue(rawProduct, "M_Product_ID");
    formattedProduct.name           = getValue(rawProduct, "Name");
    formattedProduct.value          = getValue(rawProduct, "pValue");
    formattedProduct.description    = getValue(rawProduct, "Description");
    formattedProduct.sku            = getValue(rawProduct, "sku");
    formattedProduct.status         = getValue(rawProduct, "productvaliditystatus");
    formattedProduct.imageUrl       = getValue(rawProduct, "imageUrl");
    formattedProduct.categoryID     = getValue(rawProduct, "m_product_category_id")
    formattedProduct.defaultUomID   = getValue(rawProduct, "default_uom_ID");
    formattedProduct.unitDesc       = getValue(rawProduct, "X_ProductUnit");
    formattedProduct.caseSize       = getValue(rawProduct, "x_retailcaseqty");

    formattedProduct.uom = {};
    formattedProduct.uom.id = getValue(rawProduct, "c_uom_ID");
    formattedProduct.uom.name = getValue(rawProduct, "uomName");
    // TODO set size from name
    formattedProduct.uom.uomSize = getUomSize(formattedProduct.uom.name);

    formattedProduct.caseUom = {};
    formattedProduct.caseUom.id = getValue(rawProduct, "caseUomID");
    formattedProduct.caseUom.name = getValue(rawProduct, "caseUomName");
    // TODO set size from name
    formattedProduct.caseUom.uomSize = getUomSize(formattedProduct.caseUom.name);

    formattedProduct.images = {};
    rawProduct.images?.forEach(i => {
        const [ image, ...urlData ] = i.split(":");
        formattedProduct.images[image] = urlData.join(":");
    });

    return formattedProduct;
}

const SolrProduct = {
    formatProduct
}

export default SolrProduct;

