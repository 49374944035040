import React from 'react';
import './App.css';
import {
    BrowserRouter,
} from 'react-router-dom';
import {ErrorBoundary} from "./components/general/error/ErrorBoundary";
import GeneralError from "./components/general/error/GeneralError";
import AppRoutes from "./newStructure/component/platform/AppRoutes";

const PageError = () => {
    return (
        <div className="body">
            <GeneralError />
        </div>
    )
}

function App() {

    return (
        <BrowserRouter>
            <div className="App">
                <ErrorBoundary fallBack={<PageError/>}>
                    <AppRoutes />
                </ErrorBoundary>
            </div>
        </BrowserRouter>
    );
}

export default App;
