import LineBackOrder from "../ordering/LineBackOrder";
import HeaderSkeleton from "../ordering/HeaderSkeleton";
import {range} from "../../../../res/dataServices/range";
import useBackOrders from "../../../../newStructure/services/state/account/useBackOrders";

const AccountLayoutBackOrders = () => {

    const {
        backOrderLines,
        loading,
        getStatus,
        cancelBackOrder
    } = useBackOrders();

    const isLineLoading = (boLine) => {
        return getStatus(boLine.C_OrderLine_ID.id).loading;
    }

    return (
        <>
            <h1>Back Orders</h1>
            {(loading && (!backOrderLines || backOrderLines.length === 0)) &&
                range(3).map(e => <HeaderSkeleton key={e}/>)
            }
            {backOrderLines && backOrderLines.length > 0 &&
                <div className="account-order-lines-display">
                    {backOrderLines.map((bo,i) =>
                        <LineBackOrder
                            key={i}
                            backOrderLine={bo}
                            updatingLines={isLineLoading(bo)}
                            onCancel={cancelBackOrder}
                        />
                    )}
                </div>
            }
            {!loading && (!backOrderLines || backOrderLines.length === 0) &&
                <div className="account-order-lines-display">
                    <p>You currently have no active back orders. If this doesnt seem right,
                        please get in contact via the help desk.</p>
                </div>
            }
        </>
    )
}

export default AccountLayoutBackOrders;
