import useSession from "../session/useSession";
import useValueChangeListener from "../general/useValueChangeListener";
import useAuthRequest from "../request/useAuthRequest";
import businessLocationRequest from "../../rest/request/account/businessLocationRequest";

const useBusinessLocations = () => {

    const { isSignedIn } = useSession();
    const {
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    const getLocations = () => {
        if(isSignedIn) {
            sendIt(businessLocationRequest().sendIt);
        }
    }

    useValueChangeListener(getLocations, [isSignedIn], []);

    return {
        loading,
        error,
        locations: result,
    }
}

export default useBusinessLocations;