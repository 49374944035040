import HelpDeskOverview from "../../../account/helpDesk/HelpDeskOverview";
import useHelpDesk from "../../../../newStructure/services/state/helpDesk/useHelpDesk";

const AccountLayoutGetHelp = () => {

    const {
        helpDeskItems
    } = useHelpDesk();

    return (
        <HelpDeskOverview helpDeskItems={helpDeskItems} />
    )

}

export default AccountLayoutGetHelp;
