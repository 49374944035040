import {Navigate, Route, Routes} from "react-router-dom";
import PageHome from "../page/home/PageHome";
import PageSignIn from "../page/account/PageSignIn";
import PageProductCategory from "../page/product/PageProductCategory";
import PageCategoryOverview from "../../../components/page/PageCategoryOverview";
import PageProduct from "../page/product/PageProduct";
import PageClearance from "../page/product/PageClearance";
import PagePromotion from "../page/product/PagePromotion";
import PageSearch from "../page/product/PageSearch";
import PageCheckOut from "../../../components/page/PageCheckOut";
import PagePayment from "../page/account/PagePayment";
import PageAccount from "../../../components/page/PageAccount";
import PagePurchaseOrderConfirmation from "../page/vendor/PagePurchaseOrderConfirmation";
import PageTest from "../../../components/page/PageTest";
import PageOrderEntry from "../page/product/PageOrderEntry";
import PageResetPassword from "../../../components/page/PageResetPassword";
import PageAboutUs from "../../../components/about/PageAboutUs";
import PagePrivacyStatement from "../../../components/about/PagePrivacyStatement";
import PageTermsAndConditions from "../../../components/about/PageTermsAndConditions";
import PageGeneralEnquiry from "../page/customerService/PageGeneralEnquiry";
import PageWebFeedback from "../page/customerService/PageWebFeedback";
import Page404 from "../../../components/page/Page404";
import React from "react";
import PageVendorProduct from "../page/vendor/PageVendorProduct";
import PageVendorProductCatalogue from "../page/vendor/PageVendorProductCatalogue";

const AppRoutes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<PageHome />}/>

            {/* Used to redirect customers with old link */}
            <Route exact path='/wstore' element={< PageHome />}/>
            <Route exact path='/wstore/*' element={< PageHome />}/>

            <Route exact path='/signin' element={< PageSignIn />}/>

            <Route path='/category/:categoryID' element={< PageProductCategory />}/>
            <Route path='/category/:categoryID/:pageNo' element={< PageProductCategory />}/>
            <Route path='/category/:categoryID/:pageNo/:filter' element={< PageProductCategory />}/>
            <Route path='/category' element={< PageCategoryOverview />}/>
            <Route path='/product/:productID' element={< PageProduct />}/>
            <Route path='/clearance' element={< PageClearance />}/>
            <Route path='/clearance/:pageNo' element={< PageClearance />}/>
            <Route path='/clearance/:pageNo/:filter' element={< PageClearance />}/>
            <Route path='/promotion' element={< PagePromotion />}/>
            <Route path='/promotion/:pageNo' element={< PagePromotion />}/>
            <Route path='/promotion/:pageNo/:filter' element={< PagePromotion />}/>
            <Route path='/search/:searchTerm' element={< PageSearch />}/>
            <Route path='/search/:searchTerm/:pageNo' element={< PageSearch />}/>
            <Route path='/search/:searchTerm/:pageNo/:filter' element={< PageSearch />}/>

            <Route exact path='/checkout' element={<Navigate to={"/checkout/cart"}/>}/>
            <Route path='/checkout/:checkOutStage' element={< PageCheckOut />}/>

            <Route exact path='/account/payment' element={< PagePayment />}/>
            <Route exact path='/account' element={<Navigate to={"/account/overview"}/>}/>
            <Route exact path='/account/:accountViewParent/:accountView/:id' element={< PageAccount />}/>
            <Route path='/account/:accountViewParent/:accountView' element={< PageAccount />}/>
            <Route path='/account/:accountViewParent' element={< PageAccount />}/>

            <Route path='/vendor/poConfirm/:token' element={< PagePurchaseOrderConfirmation />}/>
            <Route path='/vendor/products' element={< PageVendorProductCatalogue />}/>
            <Route path='/vendor/products/:pageNo' element={< PageVendorProductCatalogue />}/>
            <Route path='/vendor/product/:productID' element={< PageVendorProduct />}/>


            <Route exact path="/test" element={< PageTest />}/>

            <Route exact path="/orderEntry" element={< PageOrderEntry />}/>

            <Route exact path='/reset/:token' element={< PageResetPassword />}/>

            <Route exact path="/about/aboutUs" element={<PageAboutUs />}/>
            <Route exact path="/about/privacy" element={<PagePrivacyStatement />}/>
            <Route exact path="/about/termsAndConditions" element={<PageTermsAndConditions />}/>

            <Route exact path="/contact/generalEnquiry" element={<PageGeneralEnquiry/>} />
            <Route exact path="/contact/webFeedback" element={<PageWebFeedback/>} />

            <Route path='/*' element={<Page404 />}></Route>
        </Routes>
    )
}

export default AppRoutes;