
const getAdsToShow = (adList, smallScreen) => {
    if(!adList) return {};

    const ads = {};

    for(const ad of adList) {
        if(!smallScreen && ad.type === 'L' && !ads.leaderboard)
            ads.leaderboard = ad;
        if(smallScreen && ad.type === 'ML' && !ads.leaderboard)
            ads.leaderboard = ad;

        if(!smallScreen && ad.type === 'SS' && !ads.skyscraper)
            ads.skyscraper = ad;

    }

    return ads;
}


export const Advert = {
    getAdsToShow
}