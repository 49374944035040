import {useDispatch, useSelector} from "react-redux";
import Popup from "../general/popup/Popup";
import "./error.css"
import {Button} from "../general/input/Button";
import {clearErrorReport} from "../../redux/slice/errorReportSlice";

const AnError = ({message, onClick}) => {

    return (
        <div className="report-container">
            <h1>Oops! Something Went Wrong</h1>
            <p>It seems something has gone wrong. Please review the following information
                <br></br>
                <br></br>
                <b>Server Message: {message}</b>
                <br></br>
                <br></br>
                If you continue to experience difficulties, feel free to contact our support team for assistance. Thank you for your understanding.
            </p>
            <div className="report-button-container">
                <Button className="error-back-button" onClick={onClick}>Back</Button>
            </div>
        </div>
    )

}

const ErrorReport = () => {

    const dispatch = useDispatch();
    const { toReport } = useSelector(
        state => state.session.errorReport
    )

    const reportCodes = Object.keys(toReport);
    const somethingToReport = reportCodes.length;

    const clearAReport = (code) => () => {
        dispatch(clearErrorReport({ code }))
    }


    if(somethingToReport) {

        const reportKey = reportCodes[0];
        const reportError = toReport[reportKey];

        return(
            <Popup>
                <AnError message={reportError} onClick={clearAReport(reportKey)} />
            </Popup>
        )
    }

}

export default ErrorReport;