import {useSelector} from "react-redux";
import {categoryService} from "../../res/dataServices/category";
import CategoryParentButton from "./CategoryParentButton";
import {useState} from "react";
import CategoryExpand from "./CategoryExpand";
import {useNavigate} from "react-router-dom";

const CategoryBrowse = () => {

    const navigate = useNavigate();

    const { categoryMap } = useSelector(
        state =>  state.session.category
    )
    const [ expandedCategory, setExpandedCategory ] = useState();
    const categoryList = categoryService.getCategoryList(categoryMap, false);

    const navigateToCategory = (categoryID) => {
        navigate("/category/" + categoryID)
    }

    const getImageSrc = (category) => {
        if(category.imageUrl) {
            return category.imageUrl;
        } else {
            return "/assets/categoryIcons/SoftDrinks.svg";
        }
    }

    return (
        <div className="category-browse-container">
            <div className="category-browse-side-panel">
                {categoryList.map((e,i) =>
                    <CategoryParentButton
                        key={i}
                        name={e.name}
                        imgSrc={getImageSrc(e)}
                        onClick={() => setExpandedCategory(e)}
                    />
                )}
            </div>
            <div className="category-browse-main">
                <CategoryExpand category={expandedCategory} onCategorySelected={navigateToCategory}/>
            </div>
        </div>
    )
}

export default CategoryBrowse;