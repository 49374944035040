import PageVendor from "./PageVendor";
import useVendorProducts from "../../../services/state/vendor/useVendorProducts";
import VendorProductTile from "../../vendor/product/VendorProductTile";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import {useNavigate, useParams} from "react-router-dom";
import Pagination from "../../navigation/Pagination";
import {Breadcrumb, BreadcrumbNavigation} from "../../navigation/Breadcrumb";
import ProductLayout from "../../product/ProductLayout";
import ProductOptions from "../../vendor/product/ProductOptions";
import usePageBreadcrumbTracker from "../../../services/state/navigation/usePageBreadcrumbTracker";

const breadcrumbTrail = [ {name: "Home", nav: "/"}, {name: "Vendor Portal", nav: "/vendor", selected:false}, {name: "Your Products", nav: "/vendor/products", selected:true} ]

const PageVendorProductCatalogue = () => {

    const {
        pageNo: pageNoPara
    } = useParams()

    const {
        loading,
        products,
        count,
        getProducts
    } = useVendorProducts();

    const navigate = useNavigate();

    const pageNo = Number(pageNoPara ? pageNoPara : 1);
    const pageSize = 20;
    const pageCount = (Math.ceil(count / pageSize))

    const onPageChange = () => {
        getProducts({pageNo, pageSize });
    }

    useValueChangeListener(onPageChange, [pageNo], []);
    usePageBreadcrumbTracker(breadcrumbTrail, [pageNo], () => `/vendor/products/${pageNo}`);

    return (
        <PageVendor>
            <div className="page-content product-content">
                <BreadcrumbNavigation>
                    {breadcrumbTrail.map((crumb, i) =>
                        <Breadcrumb
                            key={i}
                            name={crumb.name}
                            nav={crumb.nav}
                            selected={crumb.selected}
                        />
                    )}
                </BreadcrumbNavigation>
                <ProductLayout>
                    <ProductOptions />
                    <div className="product-list">
                        {products?.map(e => <VendorProductTile product={e}/>)}
                    </div>
                </ProductLayout>
                <Pagination
                    loading={loading}
                    lastPage={pageCount}
                    currentPage={pageNo}
                    onPageRequested={(i) => navigate("/vendor/products/" + i)}
                />
            </div>
        </PageVendor>
    )
}

export default PageVendorProductCatalogue;