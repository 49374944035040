import {useState} from "react";
import {useSelector} from "react-redux";
import ProductPurchaseInfoRequest from "../../rest/request/product/productPurchaseInfoRequest";
import useRequestTracker from "../request/useRequestTracker";
import useAuthRequest from "../request/useAuthRequest";
import {getProductRequest} from "../../../logic/product/productSearchParams";

const formatProducts = (productInfo, purchaseInfo, loading) => {
    if(!productInfo) return null;

    const loadingInfo = loading;
    if(!purchaseInfo && loadingInfo) return productInfo.products.map(p => ({...p, loadingPurchaseInfo: true }));
    if(!purchaseInfo) return productInfo.products;

    return productInfo.products.map(p => {
        const info = purchaseInfo[p.id];
        if(info)
            return {...p, ...info}
        else
            return {...p, loadingPurchaseInfo: loadingInfo };
    });
}

const useSolrProduct = () => {

    const {
        loading,
        error,
        sendItWithTask
    } = useRequestTracker();
    const {
        loading : pricingLoading,
        sendIt : sendPricing
    } = useAuthRequest();

    const { session } = useSelector(
        state => state.local.auth
    )

    const [ productInfo, setProductInfo ] = useState();
    const [ purchaseInfo, setPurchaseInfo ] = useState();

    const onPurchaseInfoReceived = (result) => {
        const purchaseInfoMap = {};
        result.forEach(p => purchaseInfoMap[p.id] = p);
        setPurchaseInfo(purchaseInfoMap);
    }

    const onProductsReceived = (productInfo) => {
        const isLoggedIn = session?.bPartnerID > 0;
        if(isLoggedIn && productInfo.products.length > 0) {
            const req = ProductPurchaseInfoRequest(session.orgID, session.bPartnerID)
                .productIDs(productInfo.products.map(p => p.id));
            sendPricing(req.sendRequest, onPurchaseInfoReceived);
        }

        setProductInfo({
            count: productInfo.count,
            start: productInfo.start,
            products: productInfo.products.map(p => ({
                ...p,
                isBrochure: !isLoggedIn
            }))
        });
    }

    const getProducts = (searchParams) => {
        const req = getProductRequest(searchParams, session?.priceListID);
        sendItWithTask(req.sendRequest, onProductsReceived);
    }

    const products = formatProducts(productInfo, purchaseInfo, pricingLoading);

    return {
        loading,
        error,
        products: products,
        productCount: productInfo?.count,
        getProducts
    }
}

export default useSolrProduct;