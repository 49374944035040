import ImageLazyLoad from "../../../general/loading/ImageLazyLoad";
import {Button} from "../../../general/input/Button";
import React, {useState} from "react";
import {uomService} from "../../../../res/dataServices/uom";
import Popup from "../../../general/popup/Popup";

const DetailItem = ({heading, headingStyle, body}) => {

    return (
        <>
            <p className="placed-order-detail-h" style={{...headingStyle}}>{heading}</p>
            <p className="placed-order-detail-b">{body}</p>
        </>
    )
}

const LineBackOrder = ({ backOrderLine, updatingLines, onCancel }) => {

    const [ confirmCancel, setConfirmCancel ] = useState(false);

    const product = backOrderLine["M_Product_ID"];
    const productImage = product["ImageURL"];
    const productName  = product["Name"];
    const productValue = product["Value"];

    const orderLineID = backOrderLine["C_OrderLine_ID"].id;
    const uomQuantity = uomService.uomQuantity(backOrderLine["C_UOM_ID"]);
    const ordered     = uomQuantity.getDisplayName(backOrderLine.MovementQty);
    const stockStatus = backOrderLine["orderedstockstatus"]
    const statusName  = stockStatus["identifier"];
    const poArrival   = backOrderLine["minpoarrival"];

    const canCancel   = stockStatus.id !== 'OS';
    const updating    = updatingLines[orderLineID];

    const order = backOrderLine["C_Order_ID"]["DocumentNo"];

    const requestCancel = () => {
        if(!updating) {
            setConfirmCancel(true);
        }
    }

    const onCancelConfirm = (cancel) => () => {
        setConfirmCancel(false);
        if(cancel) {
            onCancel(orderLineID);
        }
    }

    return (
        <div className="back-order-line">
            {confirmCancel &&
                <>
                    {canCancel ?
                        <Popup>
                            <div className="report-container">
                                <h1>Cancel Back Order Line</h1>
                                <p>Are you sure you would like to cancel your back order line for {productName}.</p>
                                <div className="report-button-container">
                                    <Button className="button_clear" onClick={onCancelConfirm(false)}>Back</Button>
                                    <Button onClick={onCancelConfirm(true)}>Cancel</Button>
                                </div>
                            </div>
                        </Popup> :
                        <Popup>
                            <div className="report-container">
                                <h1>Cannot Cancel Back Order</h1>
                                <p>Sorry, but you cannot cancel this order line, as it is currently attached to an
                                    active shipment.<br></br><br></br> If this doesn't seem right, please get in contact via
                                    the help desk.</p>
                                <div className="report-button-container">
                                    <Button className="button_clear" onClick={onCancelConfirm(false)}>Back</Button>
                                </div>
                            </div>
                        </Popup>
                    }
                </>
            }
            <div className="back-order-line-header">
                <p style={{marginRight: "auto"}}>Order: {order}</p>
            </div>
            <div className="back-order-line-body">
                <ImageLazyLoad
                    className={"placed-order-line-img"}
                    src={productImage}
                    alt="Product"
                />
                <div>
                    <DetailItem
                        headingStyle={{fontSize: "1em", fontWeight: "500", color: "var(--primary)"}}
                        heading={productValue}
                        body={productName}/>
                </div>
                <div>
                    <DetailItem
                        heading="Quantity"
                        body={ordered}/>
                </div>
                <div>
                    <DetailItem
                        heading="Status"
                        body={statusName}/>
                </div>
                {poArrival &&
                    <div>
                        <DetailItem
                            heading="Estimated Replenishment Date"
                            body={poArrival}
                        />
                    </div>
                }
                <div className="back-order-button-container">
                    <Button
                        className={updating ? "button-loading" : canCancel ? "" : "inactive"}
                        onClick={requestCancel}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    )

}

export default LineBackOrder;
