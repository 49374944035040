import {Button} from "../general/input/Button";
import TextArea from "../general/input/TextArea";
import usePrint from "../general/output/usePrint";
import {request} from "../../res/rest/restRequest";
import {api} from "../../res/rest/api";
import {env} from "../../res/config/env";
import {reportError} from "../../redux/slice/errorReportSlice";
import {useDispatch} from "react-redux";
import {useState} from "react";
import {idempiereDate} from "../../res/dataServices/idempiereDate";
import ProductCheckoutTile from "../product/tile/ProductCheckoutTile";
import useCreateRequest from "../../newStructure/services/state/helpDesk/useCreateRequest";

const ExperienceFeedback = () => {

    const [ feedback, setFeedback ] = useState();
    const {
        loading,
        createRequest,
        sent
    } = useCreateRequest();

    const onFeedbackEntered = (e) => {
        const input = e.target.value;
        setFeedback(input);
    }

    const onFeedbackSubmitted = () => {
        if(feedback && feedback.length > 0 && !loading) {
            createRequest({
                requestTypeID: 1000025,
                summary: feedback
            });
        }
    }

    return (
        <div className="feedback-container">
            <h2>How was your shopping experience today?</h2>
            {!sent ?
                <>
                    <p>We’d love to hear about your experience. What was good? What could we do better?
                        Your feedback will help us improve the website!</p>
                    <TextArea onChange={onFeedbackEntered} />
                    <Button onClick={onFeedbackSubmitted}>Submit Feedback</Button>
                </> :
                <>
                    <p>Thank you for your feedback!</p>
                </>
            }
        </div>
    )
}

const OrderOverviewContainer = ({ cart }) => {

    const dispatch = useDispatch();
    const { loading: loadingPDF, fetchAndPrintIt } = usePrint({
        url: request.print(api.MODEL.C_ORDER, cart?.order?.id).buildRequest(env.API_URL),
        onError: (e) => dispatch(reportError({
            code: "shipmentPrintError",
            error: e && e.message ? e.message : "failed to fetch report"
        }))
    })

    const onPrintClick = () => {
        if(!loadingPDF) {
            fetchAndPrintIt();
        }
    }

    const orderNo = cart?.order?.DocumentNo ;
    const orderTotal = "£" + (cart?.order?.TotalLines?.toFixed(2) || "0.00");
    const deliveryMethod = cart?.order?.DeliveryViaRule.identifier;
    const deliveryDateText = (deliveryMethod === "Pickup" ? "Pickup" : "Delivery") + " Date: "
    const deliveryDate = cart && cart.order && cart.order.DatePromised ?
        idempiereDate.getDisplayDate(idempiereDate.getDate(cart.order.DatePromised)) : "";

    const lines = cart?.purchasedLines ? Object.values(cart.purchasedLines) : [];

    return (
        <div className="order-overview-container">
            <h1 className="order-overview-header">Thank you for your order</h1>
            <p>You will receive a confirmation email shortly. You can also view your new order,
                along with past orders, on the account page</p>
            <h2>Order: <span className="review-highlight">{orderNo}</span></h2>
            <h2>Order Total: <span className="review-highlight">{orderTotal}</span></h2>
            <h2>Delivery Method: <span className="review-highlight">{deliveryMethod}</span></h2>
            <h2>{deliveryDateText}<span className="review-highlight">{deliveryDate}</span></h2>
            <Button
                className={loadingPDF ? "button-loading" : ""}
                onClick={onPrintClick}
            >
                Download Order
            </Button>
            <h2 className="order-overview-line-header">Order Lines</h2>
            <div className="order-overview-lines">
                {lines.map(l => (
                    <ProductCheckoutTile cartLine={l} />
                ))}
            </div>
        </div>
    )
}

const LayoutReview = ({ cart }) => {

    return (
        <div>
            <div className="checkout-review-body">
                <OrderOverviewContainer cart={cart} />
                <ExperienceFeedback />
            </div>
        </div>
    )
}

export default LayoutReview;