import productSearchParamsBuilder from "../../../logic/product/productSearchParams";
import useSession from "../session/useSession";
import useRequestTracker from "../request/useRequestTracker";
import OrderBy from "../../../logic/filter/orderBy";

const useVendorProducts = () => {

    const session = useSession();

    const {
        loading,
        error,
        result,
        sendIt
    } = useRequestTracker();

    const getProducts = ({ pageNo, pageSize, productIDs }) => {
        const req = productSearchParamsBuilder().productIDs()
            .vendorID(session.sessionData?.bPartnerID)
            .filterAttribute("111", OrderBy.OPTION_A_Z.name);

        if(pageNo) {
            req.pageSize(pageSize)
                .pageNo(pageNo);
        }

        if(productIDs) {
            req.productIDs(...productIDs)
        }

        const builtReq = req.getProductRequest(1);
        sendIt(builtReq.sendRequest);
    }

    console.log(result)

    return {
        loading,
        error,
        products: result?.products,
        count: result?.count,
        getProducts
    }

}

export default useVendorProducts;