import {useState} from "react";
import useValueChangeListener from "../../services/state/general/useValueChangeListener";
import '../style/navigation/dropDownMenu.css'

const DropDownItem = ({ item, itemToText, selectedItem, onItemClicked }) => {

    const isSelected = item === selectedItem;

    return (
        <div
            className={"drop-down-item" + (isSelected ? " selected" : "")}
            onClick={() => onItemClicked(item)}
        >
            <p>{itemToText(item)}</p>
        </div>
    )

}

const DropDownMenu = ({ items, itemToText, getChildren, onItemSelected }) => {

    const [ optionTree, setOptionTree ] = useState([]);
    const [ selectionTree, setSelectionTree ] = useState([]);
    useValueChangeListener(() => setOptionTree([items]), [items], []);

    const onItemClicked = (item, index) => {
        const newSelectionTree = selectionTree.slice(0, index);
        newSelectionTree.push(item);
        setSelectionTree(newSelectionTree);

        const newOptionTree = optionTree.slice(0, index+1);
        const children = getChildren(item);
        console.log("children", children)
        if(children) {
            newOptionTree.push(children);
        }
        setOptionTree(newOptionTree);
        onItemSelected(item);
    };

    return (
        <div className="drop-down-menu">
            {optionTree.map((e, colIndex) =>
                <div key={colIndex}
                    className="drop-down-list">
                    {e?.map((item, index) =>
                        <DropDownItem
                            key={index}
                            item={item}
                            itemToText={itemToText}
                            selectedItem={selectionTree[colIndex]}
                            onItemClicked={(item) => onItemClicked(item, colIndex)}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default DropDownMenu;