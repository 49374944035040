import useAuthRequest from "../request/useAuthRequest";
import colleagueRequest from "../../rest/request/account/colleagueRequest";

const useColleagues = () => {

    const {
        session,
        loading,
        error,
        result,
        sendIt
    } = useAuthRequest();

    if(session.isSignedIn && !loading && !error && !result) {
        const req = colleagueRequest();
        sendIt(req.sendIt);
    }

    return {
        loading,
        error,
        colleagues: result
    }

}

export default useColleagues;