import {request} from "../../../../../res/rest/restRequest";
import {api} from "../../../../../res/rest/api";
import {env} from "../../../../../res/config/env";
import axios from "axios";
import {idempiereProcess} from "../../../../../res/rest/process";

const cancelBackOrderRequest = (orderLineID) => {

    const builder = {};

    const req = request.process(api.PROCESS.BACK_ORDER_CANCEL)
        .param("C_OrderLine_ID", orderLineID);

    builder.sendIt = async (session) => {
        const url = env.API_URL + req.buildRequest();
        const resp = axios.post(url, req.buildRequestBody(), api.auth(session));
        const data = idempiereProcess.handleResponseThrowingException(resp);
        return {orderLineID, response: data.summary};
    }

    return builder;

}

export default cancelBackOrderRequest;