import Input from "../../general/input/Input";
import {useDispatch, useSelector} from "react-redux";
import {updateOrder} from "../../../redux/action/cartActions";

const InputOrder = ({cart}) => {

    const { session } = useSelector(
        state => state.local.auth
    )

    const dispatch = useDispatch();

    const orderID = cart.order.id;
    const orderNo = cart.order["DocumentNo"];
    const currentRef = cart.order.POReference;
    const currentCode = cart.order.PromotionCode;

    const onReferenceChanged = (event) => {
        const input = event.target.value;
        if(input !== currentRef) {
            const toChange = {"POReference": input}
            dispatch(updateOrder({session, orderID, toChange}))
        }

    }

    const onPromotionChanged = (event) => {
        const input = event.target.value;
        if(input !== currentCode) {
            const toChange = {"promotionCode": input}
            dispatch(updateOrder({session, orderID, toChange}))
        }
    }

    return (
        <>
            <p className="form-sub-heading">Order</p>
            <p className="form-text-data">{orderNo}</p>
            <p className="form-sub-heading">Order Reference</p>
            <Input defaultValue={currentRef} onBlur={onReferenceChanged}/>
            <p className="form-sub-heading">Promotion Code</p>
            <Input defaultValue={currentCode} onBlur={onPromotionChanged}/>
        </>
    )
}

export default InputOrder;