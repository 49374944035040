import Input from "../../../components/general/input/Input";
import {Button} from "../../../components/general/input/Button";
import SignInContent from "./SignInContent";
import useCreateHelpDeskRequest from "../../services/state/helpDesk/useCreateRequest";
import {useState} from "react";
import HelpDeskRequest from "../../logic/helpDesk/helpDeskRequest";

const getAccountRequestSummary = (input) => {

    return `Account creation request from webstore.\n
    Name: ${input.name},
    Business: ${input.business},
    Email: ${input.email},
    Phone: ${input.phone}`

}

const SignUpInput = () => {

    const {
        loading,
        createRequest,
        sent
    } = useCreateHelpDeskRequest();

    const [ warning, setWarning ] = useState();
    const [ userInput, setUserInput ] = useState({});

    const isEmpty = (text) => {
        return !text || text.trim() === "";
    }

    const createAccountRequest = () => {
        if(loading) return;
        if(isEmpty(userInput.name)) {
            setWarning("Please provide a name.");
        } else if(isEmpty(userInput.business)) {
            setWarning("Please provide a business name.");
        } else if(isEmpty(userInput.phone) && isEmpty(userInput.email)) {
            setWarning("Please provide a phone number, an email, or both")
        } else {
            createRequest({
                requestTypeID: HelpDeskRequest.TYPE_ID.NEW_ACCOUNT,
                summary: getAccountRequestSummary(userInput)
            });
        }
    }

    const onUserInput = (key) => (event) => {
        setUserInput({
            ...userInput,
            [key]: event.target.value
        })
    }

    return (
        <SignInContent header="Not a customer yet?">
            {!sent &&
                <>
                    <p className="sign-in-sub-heading">Sign up for an account with us</p>
                    <p className="sign-in-text">When you become a Sutherland Brothers customer, you'll get to</p>
                    <li className="sign-in-text">Buy from an award winning wholesaler</li>
                    {/*<li className="sign-in-text">thing</li>*/}
                    <p className="sign-in-sub-sub-heading">Sign up now</p>
                    <p className="sign-in-text">Our accounts are created by a sales representative. Please leave us your
                        contact information so we can get in touch to get you started.</p>
                    <Input hint="Name" onChange={onUserInput("name")} />
                    <Input hint="Business Name" onChange={onUserInput("business")}/>
                    <Input hint="Email" onChange={onUserInput("email")}/>
                    <Input hint="Contact Number" onChange={onUserInput("phone")} />
                    {warning &&
                        <p className="sign-in-error">{warning}</p>
                    }
                    <Button
                        className={loading ? "loading" : ""}
                        onClick={createAccountRequest}
                    >
                        Sign up
                    </Button>
                </>
            }
            {sent &&
                <>
                    <p className="sign-in-sub-heading">Thank you!</p>
                    <p>A sales rep will be in touch shortly, to get you started with an account.</p>
                </>
            }
        </SignInContent>
    )

}

export default SignUpInput;
