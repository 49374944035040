import PageLayout from "../../../../components/page/PageLayout";
import PaymentMethodSwitcher from "../../payment/PaymentMethodSwitcher";
import {PaymentRules} from "../../../logic/payment/paymentRules";
import Input from "../../../../components/general/input/Input";
import {Button} from "../../../../components/general/input/Button";
import {useState} from "react";
import CreditCard from "../../../logic/payment/creditCard";
import {AddressInput} from "../../../../components/checkout/form/InputDelivery";
import '../../style/page/pagePayment.css'
import PaymentProcessor, {runInitialPaymentStep} from "../../../../components/payment/PaymentProcessor";
import {useDispatch, useSelector} from "react-redux";
import useValueChangeListener from "../../../services/state/general/useValueChangeListener";
import Popup from "../../../../components/general/popup/Popup";
import useCreditStatus from "../../../services/state/account/useCreditStatus";
import useBusinessLocations from "../../../services/state/account/useBusinessLocations";
import useInvoices from "../../../services/state/account/useInvoices";
import SkeletonDiv from "../../../../components/general/loading/SkeletonDiv";

const unpaidInvoices = {
    isPaid: false,
    oldestFirst: true,
    pageSize: 100,
    includeLines: false,
    includeAllocation: true,
}

const formatDisplayPrice = (number) => {
    if(isNaN(number)) return "---"
    // Convert number to a fixed decimal with 2 digits
    const fixedNumber = number.toFixed(2);

    // Add thousands separator (comma) if applicable
    const parts = fixedNumber.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Return the formatted price
    return `£${parts.join(".")}`;
}

const formatDetails = (session, cardInput, amount, location) => {

    return {
        ...cardInput,
        currency: 'GBP',
        amount: Number(amount).toFixed(2),
        locationID: location.id,
        bPartnerID: session.bPartnerID,
        userID: session.userID,
    }
}

const OpenInvoice = ({ invoice }) => {

    const allocationLines = invoice.C_AllocationLine
    const allocated = allocationLines ? allocationLines.reduce((amnt, line) => {
        return amnt + line.Amount;
    }, 0) : 0;
    const grandTotal = invoice.GrandTotal;
    const openBalance = grandTotal - allocated;

    return (
        <div className="open-invoice">
            <p>Invoice<br/><span>{invoice.DocumentNo}</span></p>
            <p>Grand Total<br/><span>{formatDisplayPrice(grandTotal)}</span></p>
            <p>Remaining<br/><span>{formatDisplayPrice(openBalance)}</span></p>
        </div>
    )
}

const PagePayment = () => {

    const dispatch = useDispatch();

    const { session } = useSelector(state => state.local.auth);
    const { creditStatus, loading, getCreditStatus } = useCreditStatus();
    const { locations } = useBusinessLocations();
    const { paymentResult, error: paymentError } = useSelector(
        state => state.session.payment
    );
    const { loading: loadingInvoices, invoices, getInvoices } = useInvoices();

    const [ cardInput, setCardInput ] = useState();
    const [ billingAddress, setBillingAddress ] = useState();
    const [ paymentAmount, setPaymentAmount ] = useState();
    const [ showPaymentMade, setShowPaymentMade ] = useState(false);

    // const savedCards = Banking.filterSavedCards(banking);
    const readyToMakePayment = CreditCard.validateCardInput(cardInput).ok
        && billingAddress
        && paymentAmount
        && paymentAmount > 0;

    const onPayClick = () => {
        if(readyToMakePayment) {
            const details = formatDetails(session, cardInput.cardInput, paymentAmount, billingAddress);
            runInitialPaymentStep(dispatch, session, details);
        }
    }

    const onPaymentMade = () => {
        if (paymentResult?.success) {
            setShowPaymentMade(true);
            getCreditStatus();
        }
    }

    useValueChangeListener(onPaymentMade, [ paymentResult?.success ]);
    useValueChangeListener(() => getInvoices(unpaidInvoices), [ paymentResult?.success ], []);

    return (
        <PageLayout requireSignIn>
            <div className="page-content page-min-height page-content-buffer">
                <div className="page-readable-width page-payment-content">
                    <p className="page-h1">Make Payment</p>
                    <p className="page-h5">Credit Limit</p>
                    <p className={loading ? "loading" : "page-h4"}>£{creditStatus?.SO_CreditLimit}</p>
                    <p className="page-h5">Open Balance</p>
                    <p className={loading ? "loading" : "page-h4"}>£{creditStatus?.TotalOpenBalance}</p>
                    <p className="page-h5">Invoices With Open Balance</p>
                    {loadingInvoices ?
                        <SkeletonDiv style={{height: "24px", marginTop: "8px", width: "400px"}} /> :
                        (invoices && invoices.length > 0) ?
                            <div>
                                {invoices.map((inv, i) => <OpenInvoice key={i} invoice={inv}/>)}
                            </div> :
                            <p style={{fontSize: "20px"}} className="page-h4">All Invoices Paid</p>
                    }
                    <div className="page-payment-input-container">
                        <p className="page-h4">Payment Amount £</p>
                        <Input
                            hint="Please enter payment amount."
                            onBlur={e => setPaymentAmount(e.target.value)}/>
                        <p className="page-h4">Billing Address</p>
                        {locations &&
                            <AddressInput
                                selectedAddress={billingAddress}
                                locations={locations}
                                onSelect={setBillingAddress}
                                setCheckoutBlock={() => {
                                }}
                            />
                        }
                        {!billingAddress &&
                            <p style={{fontWeight: 600, margin: "20px 0", color: "var(--warning)"}}>
                                Please select a billing address
                            </p>
                        }
                        <p className="page-h4">Card Input</p>
                        <PaymentMethodSwitcher
                            paymentRules={[PaymentRules.K]}
                            selectedRule={PaymentRules.K}
                            onCardInput={setCardInput}
                            savedCards={[]}
                        />
                        {(paymentError || paymentResult?.declined) &&
                            <p className="warning">Payment has been declined. Please check card details.</p>
                        }
                        <Button
                            onClick={onPayClick}
                            className={readyToMakePayment ? "payment-button" : "payment-button inactive"}>
                            Make Payment
                        </Button>
                    </div>
                    <PaymentProcessor session={session}/>
                </div>
            </div>
            {showPaymentMade &&
                <Popup
                    onOverlayClick={() => setShowPaymentMade(false)}
                >
                    <div className="page-payment-popup-container">
                        <p className="page-h2">Payment Made Successfully</p>
                        <p className="page-payment-notify">Your payment has been received. Thank You.</p>
                        <Button onClick={() => setShowPaymentMade(false)}>Back</Button>
                    </div>
                </Popup>
            }
        </PageLayout>
    )
}

export default PagePayment;