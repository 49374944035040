import {useState} from "react";
import '../style/promotion/promotionCreator.css'
import RangeDatePicker from "../general/input/RangeDatePicker";

const TYPE_HOME      = "home";
const TYPE_CATEGORY  = "category";
const TYPE_RELATED   = "related-product";

const BANNER_BOTH           = "both";
const BANNER_LEADERBOARD    = "leaderboard";
const BANNER_SKYSCRAPER     = "skyscraper"

const BannerLocationSelector = ({ selected, onChange }) => {

    const bannerClass = (type) => "promotion-creator-banner" + (selected === type ? " selected" : "");
    const onClick = (type) => () => onChange(type);

    return (
        <div className="promotion-creator-banner-container">
            <div className={bannerClass(BANNER_SKYSCRAPER)} onClick={onClick(BANNER_SKYSCRAPER)}>
                <img className="promotion-creator-banner-img" src="/assets/ad-skyscraper-selected.svg" alt=""/>
                <p>Skyscraper</p>
            </div>
            <div className={bannerClass(BANNER_LEADERBOARD)} onClick={onClick(BANNER_LEADERBOARD)}>
                <img className="promotion-creator-banner-img" src="/assets/ad-leaderboard-selected.svg" alt=""/>
                <p>Leaderboard</p>
            </div>
            <div className={bannerClass(BANNER_BOTH)} onClick={onClick(BANNER_BOTH)}>
                <img className="promotion-creator-banner-img" src="/assets/ad-both-selected.svg" alt=""/>
                <p>Both</p>
            </div>
        </div>
    )

}

const TypeSelector = ({selected, onChange}) => {

    const getClassName = (type) => (type === selected) ? "selected" : null;
    const onClick = (type) => () => onChange(type);

    return (
        <div className="promotion-creator-selector">
            <p className={getClassName(TYPE_HOME)} onClick={onClick(TYPE_HOME)} >
                Home Page
            </p>
            <p className={getClassName(TYPE_CATEGORY)} onClick={onClick(TYPE_CATEGORY)} >
                Category Page
            </p>
            <p className={getClassName(TYPE_RELATED)} onClick={onClick(TYPE_RELATED)} >
                Related Product
            </p>
        </div>
    )
}

const DateRangeSelector = ({ setDates }) => {

    return (
        <div className="promotion-creator-date-container">
            <RangeDatePicker
                maxMonths={0}
                maxWeeks={4}
                minDate={new Date()}
                onChange={setDates}
            />
        </div>
    )
}

const PromotionCreator = () => {

    const [ promo, setPromo ] = useState({
        promoType: TYPE_CATEGORY,
        bannerType: BANNER_SKYSCRAPER
    });

    console.log(promo)

    return (
        <div className="promotion-creator-container">
            <p className="promotion-creator-heading">Promotion Type</p>
            <TypeSelector selected={promo.promoType} onChange={promoType => setPromo({...promo, promoType})}/>
            <p className="promotion-creator-heading">Promotion Date Range</p>
            <DateRangeSelector setDates={dates => setPromo({...promo, ...dates})}/>
            {promo.promoType === TYPE_CATEGORY &&
                <>
                    <p className="promotion-creator-heading">Banner Location</p>
                    <BannerLocationSelector
                        selected={promo.bannerType}
                        onChange={bannerType => setPromo({...promo, bannerType})}
                    />
                </>
            }
        </div>
    )


}

export default PromotionCreator;