import Checkbox from "../general/input/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import {setRememberMe} from "../../../redux/slice/authSlice";

const RememberMe = () => {

    const dispatch = useDispatch();
    const { rememberMe } = useSelector(state => state.local.auth);

    const onCheckedChange = (isChecked) => {
        dispatch(setRememberMe({isChecked}))
    }

    return (
        <div className="sign-in-remember-me">
            <Checkbox
                isChecked={rememberMe}
                onCheckedChange={onCheckedChange}
            />
            <p className="sign-in-sub-heading">Remember me</p>
        </div>
    )
}

export default RememberMe;