import useSession from "../../../services/state/session/useSession";
import Page404 from "../../../../components/page/Page404";
import PageLayout from "../../../../components/page/PageLayout";

const PageVendor = ({ children }) => {

    const session = useSession();
    if(!session.isVendor) {
        return <Page404 />
    }

    return (
        <PageLayout>
            {children}
        </PageLayout>
    )

}

export default PageVendor;