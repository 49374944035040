import {useNavigate} from "react-router-dom";
import '../style/promotion/advert.css'

const Skyscraper = ({ imageUrl, link }) => {

    const navigate = useNavigate();
    const onClick = () => {
        navigate(link);
    }

    return (
        <div
            className="skyscraper"
            onClick={onClick}
            style={{backgroundImage: `url(${imageUrl})`}}
        />
    )

}

export default Skyscraper;