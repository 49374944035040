import '../style/home/landingLayout.css'
import {useNavigate} from "react-router-dom";

const LandingLayout = () => {

    const navigate = useNavigate();

    const onSignInClick = () => {
        navigate("/signin");
    }

    return (
        <div className="landing-layout">
            <div className="landing-layout-header landing-layout-header-img">
                <div className="landing-layout-heading">
                    <p>Trusted throughout the Highlands & Islands Since 1892</p>
                </div>
                <div className="landing-layout-note-container">
                        <div className="landing-layout-note">
                            <p className="landing-layout-note-heading">Get your personalised account</p>
                            <p className="landing-layout-note-text">
                                Sign up via the
                                <span style={{cursor: "pointer"}} className="bold" onClick={onSignInClick}> sign in  </span>
                                button today to open an account with Sutherland Brothers Ltd
                            </p>
                        </div>
                </div>
            </div>
            <div className="landing-layout-content">
                <p className="landing-content-heading">Retail, Ontrade, Foodservice</p>
                <p className="landing-content-sub-heading">Whatever your business, we've got you covered</p>
                <div className="landing-content-grid">
                    <div>
                        <p className="landing-content-grid-heading bold">Great range of products</p>
                        <p className="landing-content-grid-text">All the household brands your customers know and love -
                            plus an exciting range of bespoke local products.
                            <br/>
                            Our website features realtime stock listings - so you always know if items are in stock
                        </p>
                    </div>
                    <div>
                        <p className="landing-content-grid-heading bold">Friendly service</p>
                        <p className="landing-content-grid-text">You can access your entire account online - we make it
                            simple to place orders and make any changes you need.
                            <br/>
                            Plus you'll get a dedicated Account Manager - so help is always just a click or a phone call
                            away.
                        </p>
                    </div>
                    <div>
                        <p className="landing-content-grid-heading bold">Late Order Cut-off</p>
                        <p className="landing-content-grid-text">We can accept order right up until our 4pm cutoff for
                            delivery tommorrow.
                            <br/>
                            That gives you a chance to order when it suits you.
                        </p>
                    </div>
                    <div>
                        <p className="landing-content-grid-heading bold">Dependable delivery</p>
                        <p className="landing-content-grid-text">We'll tell you when to expect our Delivery Team when we
                            are delivering so you can get on with running your business.
                            <br/>
                            And we have our own vehicle fleet - driven by our dependable Driver Team who will deliver
                            direct to your door.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )

}

export default LandingLayout;
